import React from 'react'

/**
 * @param {*} props.label
 * @param {*} props.name
 * @param {*} props.id
 * @param {*} props.value
 * @param {*} props.checked
 * @param {*} props.disabled
 * @param {*} props.readOnly
 * @param {*} props.onChange
 */
const CheckboxGroup = props => {
    return (
        <div className="CheckboxGroup checkbox-group">
            <input
                type="checkbox"
                name={props.name}
                id={props.id}
                value={props.value}
                disabled={props.disabled}
                readOnly={props.readOnly}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
                onChange={props.onChange}
                onClick={props.onClick}
            />
            <label htmlFor={props.id} className="label" style={{ paddingTop: 2 }}>
                {props.label}
            </label>
        </div>
    )
}

export default CheckboxGroup
