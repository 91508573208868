import React, { Component } from 'react'
import './style.scss'

export default class BorderCard extends Component {
  render() {
    return (
    <div className="border-card" style={{borderColor: this.props.corBorda}}>
        {this.props.children}
    </div>
    )
  }
}
