import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './style.module.scss'


export default class DesktopMenuItem extends Component {
    render() {
        return (
            <li className={style.menuitem}>
                {
                    this.props.linkUrl
                    ?
                    <a href={this.props.linkUrl} target="_blank">
                        <div className="nome-item">
                            {this.props.textoItem}
                        </div>
                        <div className="icone-dropdown">
                            {this.props.iconName &&
                                <FontAwesomeIcon icon={this.props.iconName} />
                            }
                        </div>
                    </a> 
                    :
                    <Link to={this.props.linkItem}>
                        <div className="nome-item">
                            {this.props.textoItem}
                        </div>
                        <div className="icone-dropdown">
                            {this.props.iconName &&
                                <FontAwesomeIcon icon={this.props.iconName} />
                            }
                        </div>
                    </Link> 
                }
            </li>
        )
    }
}
