import React, { Component } from 'react'
import Button from '../Button/Button'

export default class InternalCommentItem extends Component {
    state = {
        originalText: this.props.text,
        text: this.props.text ? this.props.text.substr(0, 100) : '',
        hideButton: this.props.text.length < 100
    }

    render() {
        const { item } = this.props
        const author = `${item.user.first_name} ${item.user.last_name || ''}`

        return (
            <div className="internal-comment-item">
                <div className="comment-owner">{author}</div>
                <div className="comment-date-container">
                    <div className="comment-created">{this.formatDate(item.created_at)}</div>
                    {item.created_at !== item.updated_at && (
                        <div className="comment-updated">{`Atualizado em ${this.formatDate(
                            item.updated_at,
                            true
                        )}`}</div>
                    )}
                </div>
                <div className="comment-content">{this.state.text}</div>
                <div>
                    {!this.state.hideButton && (
                        <button href="" className="button see-more" style={{ padding: 0 }} onClick={this.showFullText}>
                            Ver mais
                        </button>
                    )}
                    <button
                        className="button see-more"
                        style={{ padding: 0 }}
                        onClick={() =>
                            this.props.onEditNote(this.props.id, this.props.text, {
                                name: author,
                                date: this.formatDate(item.created_at)
                            })
                        }
                    >
                        Editar
                    </button>
                    <button
                        className="button delete"
                        style={{ padding: 0 }}
                        onClick={() => this.props.onDeleteNote(this.props.id)}
                    >
                        Excluir
                    </button>
                </div>
            </div>
        )
    }

    componentDidUpdate() {
        if (this.props.text !== this.state.originalText)
            this.setState({
                originalText: this.props.text,
                text: this.props.text ? this.props.text.substr(0, 100) : '',
                hideButton: this.props.text.length < 100
            })
    }

    showFullText = () => {
        this.setState({ text: this.props.text, hideButton: true })
    }

    formatDate = (date, hideHours) => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hour = date.substr(10, 6)

        let text = `${day}/${month}/${year}`

        if (!hideHours) text += ` às ${hour}`

        return text
    }
}
