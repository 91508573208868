import { LOGIN, LOGOUT, LOGIN_ERROR, LOGIN_LOADING, UPDATE_USER } from '../actions/actionTypes';

const initialState = {
    user: JSON.parse(localStorage.getItem('@IguatemiOuvidoriaAdmin:admin')) || null,
    error: {},
    loading: false,
}

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.payload,
                error: {}
            }
        case LOGOUT:
            return {
                ...state,
                user: null,
                error: {}
            }
        case LOGIN_ERROR:
            return {
                ...state,
                user: null,
                error: action.payload
            }
        case LOGIN_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case UPDATE_USER:
            localStorage.setItem('@IguatemiOuvidoriaAdmin:admin', JSON.stringify(action.payload))
            return {
                ...state,
                user: action.payload
            }
        default:
            return state
    }
}