import React, { Component } from 'react'
import SelectInput from '../Inputs/SelectInput'
import './style.scss'
import TitleGoBackButton from '../Title/TitleGoBackButton'

export default class TicketTopInfo extends Component {
    render() {
        const { customer, statusData } = this.props
        return (
            <div>
                <div className="topo-container">
                    <TitleGoBackButton goBackText="Voltar para ocorrências" goBackClick={this.props.onGoBackClick} />
                    <div className="top-row-container">
                        <div className="protocol-info">
                            <div className="protocol-tag">Protocolo:</div>
                            <div className="protocol-number">{this.props.protocolNumber}</div>
                        </div>
                        <div className="select-container">
                            <span>Status:</span>
                            <SelectInput
                                defaultValue={this.props.statusData.value}
                                value={this.props.statusData.value}
                                options={this.props.statusData.options}
                                onChange={this.props.statusData.onChange}
                            />
                        </div>
                    </div>
                    <div className="ticket-info-listing">
                        {customer && (
                            <div className="listing-item">
                                <div className="item-tag">Criador por:</div>
                                <div className="item-value">{`${customer.first_name} ${customer.last_name || ''}`}</div>
                            </div>
                        )}
                        {customer && (
                            <div className="listing-item">
                                <div className="item-tag">CPF/CNPJ:</div>
                                <div className="item-value">{customer.document_number}</div>
                            </div>
                        )}
                        {customer && (
                            <div className="listing-item">
                                <div className="item-tag">Email:</div>
                                <div className="item-value">{customer.email}</div>
                            </div>
                        )}
                        <div className="listing-item">
                            <div className="item-tag">Data de criação:</div>
                            <div className="item-value">{this.props.creationDate}</div>
                        </div>
                        {customer && (
                            <div className="listing-item">
                                <div className="item-tag">Telefone:</div>
                                <div className="item-value">{customer.cellphone || 'Nenhum telefone cadastrado'}</div>
                            </div>
                        )}
                        <div className="listing-item">
                            <div className="item-tag">Última Atualização:</div>
                            <div className="item-value">{this.props.lastModificationDate}</div>
                        </div>
                        <div className="listing-item" style={{ width: '50%' }}>
                            <div className="item-tag">Data de expiração por falta de retorno do usuário:</div>
                            <div className="item-value">{this.props.expirationDate || 'Não Configurada'}</div>
                        </div>                        
                    </div>
                </div>
            </div>
        )
    }
}
