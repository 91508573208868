import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import './style.scss'

export default class BoxInfoAdmin extends Component {
    render() {
        const { scoreboard } = this.props

        return (
            <div className="box-info-admin-container">
                <div className="info-item">
                    <div className="icone">
                        <FontAwesomeIcon icon="plus-circle" />
                    </div>
                    <Link to={{ pathname: '/admin-ocorrencias', state: { situation: '0' } }}>
                        <div className="conteudo">
                            <div className="numero">{scoreboard[0]}</div>
                            <div className="text">Novas</div>
                            <div className="descricao">Ocorrências novas que ainda não foram atendidas</div>
                        </div>
                    </Link>
                </div>

                <div className="info-item">
                    <div className="icone">
                        <FontAwesomeIcon icon="info-circle" />
                    </div>
                    <Link to={{ pathname: '/admin-ocorrencias', state: { situation: '1' } }}>
                        <div className="conteudo">
                            <div className="numero">{scoreboard[1]}</div>
                            <div className="text">Ativas</div>
                            <div className="descricao">Ocorrências já atendidas que aguardam feedback</div>
                        </div>
                    </Link>
                </div>

                <div className="info-item">
                    <div className="icone">
                        <FontAwesomeIcon icon="info-circle" />
                    </div>
                    <Link to={{ pathname: '/admin-ocorrencias', state: { situation: '2' } }}>
                        <div className="conteudo">
                            <div className="numero">{scoreboard[2]}</div>
                            <div className="text">Respondidas</div>
                            <div className="descricao">Ocorrências ativas que receberam feedback</div>
                        </div>
                    </Link>
                </div>

                <div className="info-item">
                    <div className="icone">
                        <FontAwesomeIcon icon="check-circle" />
                    </div>
                    <Link to={{ pathname: '/admin-ocorrencias', state: { situation: '3' } }}>
                        <div className="conteudo">
                            <div className="numero">{scoreboard[3]}</div>
                            <div className="text">Concluídas</div>
                            <div className="descricao">Ocorrências com atendimento concluído</div>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}
