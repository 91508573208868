import React, { Component } from 'react'
import classNames from 'classnames'

import iconClose from '../../assets/images/icon-close.png'

import './style.sass'

class Modal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isActive: this.props.isActive ? this.props.isActive : false,
        }

        this.customClass = this.props.customClass ? this.props.customClass : undefined

        this.handleEscPress()
    }

    openModal = () => this.setState({ isActive: true })
    closeModal = () => {
        this.setState({ isActive: false }, () => {
            this.props.onClose && this.props.onClose()
        })
    }

    handleEscPress = () => {
        window.addEventListener('keydown', event => {
            if (event.code === 'Escape' && this.state.isActive === true) {
                this.closeModal()
            }
        })
    }

    render() {
        const modalStatusClass = classNames({
            Modal: true,
            [this.customClass]: this.customClass,
            'is-active': this.state.isActive,
        })

        return (
            <div className={modalStatusClass}>
                <div className="Modal-bg" onClick={this.closeModal} />
                <div className="Modal-box">
                    <div className="Modal-header">
                        <div className="Modal-title">{this.props.title}</div>
                        <div className="Modal-close" onClick={this.closeModal}>
                            <img src={iconClose} alt="Fechar" />
                        </div>
                    </div>
                    <div className="Modal-body">
                        <div className="Modal-content">{this.props.children}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal
