import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import InputMask from 'react-input-mask'
import './style.scss'


export default class TextInputMask extends Component {

    render() {
        const { onChange, value, iconName, labelInput, colorLabel, isIconRight, mask, onIconClick } = this.props

        const sideControl = classNames("icon-container", { "right": isIconRight, "left": !isIconRight })
        const paddingControl = classNames("input", { "padding": !isIconRight })

        return (
            <div className="item-input icon-inside">
                <label style={{ color: colorLabel }}>{labelInput}</label>
                <InputMask
                    mask={mask}
                    onChange={onChange}
                    value={value}
                    className={paddingControl}
                />
                {
                    iconName &&
                    <div className={sideControl} onClick={onIconClick} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={iconName} />
                    </div>
                }
                {
                    this.props.msgErro &&
                    <div className="msg-erro"> <FontAwesomeIcon icon="info-circle" /> {this.props.msgErro}</div>
                }
            </div>
        )
    }
}
