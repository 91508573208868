import React, { Component } from 'react'
import './InternalComment.styles.scss'
import Button from '../Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import icone from '../../assets/images/file-text.png'
import InternalCommentItem from '../InternalCommentItem/InternalCommentItem'
import AddInternalComment from '../AddInternalComment/AddInternalComment'
import AddReport from '../AddReport/AddReport'

export default class InternalComment extends Component {
    render() {
        return (
            <div className="internal-comment-container">
                <div className="small-title">Comentários Internos</div>
                {this.props.notes &&
                    this.props.notes.map((item, index) => (
                        <InternalCommentItem
                            id={item.id}
                            onEditNote={this.props.onEditNote}
                            onDeleteNote={this.props.onDeleteNote}
                            text={item.content}
                            item={item}
                            key={index}
                        />
                    ))}
                <AddInternalComment
                    textValue={this.props.textValue}
                    onTextChange={this.props.onTextChange}
                    onSendNote={this.props.onSendNote}
                />
                <AddReport
                    onSendReport={this.props.onSendReport}
                    onDeleteReport={this.props.onDeleteReport}
                    reports={this.props.reports}
                    isLocked={this.props.isLocked}
                />
            </div>
        )
    }
}
