import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

export default class ListagemItem extends Component {
    render() {
        return this.props.isClient ? (
            <div className="listagem-item" onClick={this.props.onClick}>
                <div className="coluna protocolo client">{this.props.name}</div>
                <div className="coluna client">{this.props.email}</div>
                <div className="coluna client">{this.props.lastLogin}</div>
                <div className="coluna status client">
                    {this.props.occurrences || '-'}
                    <div className="icone">
                        <FontAwesomeIcon icon="chevron-right" />
                    </div>
                </div>
            </div>
        ) : this.props.gestao ? (
            <div className="listagem-item" onClick={!this.props.notEditable ? this.props.onClick : () => {}}>
                <div className="coluna protocolo sort">{this.props.name}</div>
                <div className="coluna protocolo sort">{this.props.sort}</div>
                <div className="coluna status gestao">
                    {this.props.status}
                    {!this.props.notEditable && (
                        <div className="icone">
                            <FontAwesomeIcon icon="pencil-alt" />
                        </div>
                    )}
                </div>
            </div>
        ) : this.props.admin ? (
            <div className="listagem-item" onClick={!this.props.notEditable ? this.props.onClick : () => {}}>
                <div className="coluna protocolo">{this.props.name}</div>
                <div className="coluna">{this.props.email}</div>
                <div className="coluna status">
                    {this.props.status}
                    {!this.props.notEditable && (
                        <div className="icone">
                            <FontAwesomeIcon icon="pencil-alt" />
                        </div>
                    )}
                </div>
            </div>
        ) : this.props.isClientInterna ? (
            <div className="listagem-item" onClick={this.props.onClick}>
                <div className="coluna protocolo super-small">{this.props.valorProtocolo}</div>
                <div className="coluna small">{this.props.valorLocal}</div>
                <div className="coluna small">{this.props.valorCriacao}</div>
                <div className="coluna small">{this.props.valorAtt}</div>
                <div className="coluna small">{this.situationName(this.props.valorSituacao)}</div>
                <div className="coluna status small">
                    {this.props.valorStatus}
                    <div className="icons">
                        {this.props.hasReports && (
                            <div className="icone">
                                <FontAwesomeIcon icon="paperclip" />
                            </div>
                        )}
                        {this.props.hasNotes && (
                            <div className="icone">
                                <FontAwesomeIcon icon="comment-dots" />
                            </div>
                        )}
                        <div className="icone">
                            <FontAwesomeIcon icon="chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="listagem-item" onClick={this.props.onClick}>
                <div className="coluna protocolo super-small">{this.props.valorProtocolo}</div>
                <div className="coluna small">{this.props.valorUsuario || 'Anônimo'}</div>
                <div className="coluna small">{this.props.valorLocal}</div>
                <div className="coluna small">{this.props.valorCriacao}</div>
                <div className="coluna small">{this.props.valorAtt}</div>
                <div className="coluna small">{this.situationName(this.props.valorSituacao)}</div>
                <div className="coluna status small">
                    {this.props.valorStatus}
                    <div className="icons">
                        {this.props.hasReports && (
                            <div className="icone">
                                <FontAwesomeIcon icon="paperclip" />
                            </div>
                        )}
                        {this.props.hasNotes && (
                            <div className="icone">
                                <FontAwesomeIcon icon="comment-dots" />
                            </div>
                        )}
                        <div className="icone">
                            <FontAwesomeIcon icon="chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    situationName = id => {
        if (id === 0) return 'Nova'
        if (id === 1) return 'Ativa'
        if (id === 2) return 'Respondida'
        if (id === 3) return 'Concluída'

        return '-'
    }
}
