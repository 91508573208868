import React, { Component } from 'react'
import SelectInput from '../../components/Inputs/SelectInput'
import { type } from 'os'
export default class TicketInputListing extends Component {
    render() {
        const { mallData, sectorData, topicData, typeData, isFinished } = this.props

        if (isFinished) {
            return (
                <div className="ticket-input-listing">
                    <div className="ticket-input-item">
                        <div className="item-tag">Assunto:</div>
                        <div className="item-value-finished">
                            {topicData.options.find(item => item.id.toString() === topicData.value.toString()).name}
                        </div>
                    </div>
                    <div className="ticket-input-item">
                        <div className="item-tag">Tipo:</div>
                        <div className="item-value-finished">
                            {typeData.options.find(item => item.id.toString() === typeData.value.toString()).name}
                        </div>
                    </div>
                    <div className="ticket-input-item">
                        <div className="item-tag">Setor:</div>
                        <div className="item-value-finished">
                            {sectorData.options.find(item => item.id.toString() === sectorData.value.toString()).name}
                        </div>
                    </div>
                    <div className="ticket-input-item">
                        <div className="item-tag">Localidade:</div>
                        <div className="item-value-finished">
                            {mallData.options.find(item => item.id.toString() === mallData.value.toString()).name}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="ticket-input-listing">
                <div className="ticket-input-item">
                    <div className="item-tag">Assunto:</div>
                    <div className="item-value">
                        <SelectInput
                            defaultValue={topicData.value}
                            value={topicData.value}
                            options={topicData.options}
                            onChange={topicData.onChange}
                        />
                    </div>
                </div>
                <div className="ticket-input-item">
                    <div className="item-tag">Tipo:</div>
                    <div className="item-value">
                        <SelectInput
                            defaultValue={typeData.value}
                            value={typeData.value}
                            options={typeData.options}
                            onChange={typeData.onChange}
                        />
                    </div>
                </div>
                <div className="ticket-input-item">
                    <div className="item-tag">Setor:</div>
                    <div className="item-value">
                        <SelectInput
                            defaultValue={sectorData.value}
                            value={sectorData.value}
                            options={sectorData.options}
                            onChange={sectorData.onChange}
                        />
                    </div>
                </div>
                <div className="ticket-input-item">
                    <div className="item-tag">Localidade:</div>
                    <div className="item-value">
                        <SelectInput
                            defaultValue={mallData.value}
                            value={mallData.value}
                            options={mallData.options}
                            onChange={mallData.onChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
