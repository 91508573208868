import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import MessageBox from '../../components/MessageBox'

import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import TextInput from '../../components/Inputs/TextInput'
import PasswordInput from '../../components/Inputs/PasswordInput'
import Button from '../../components/Button/Button'
import UnderlinedLink from '../../components/UnderlinedLink'
import arvore from '../../assets/images/arvore-card.png'
import LoaderFull from '../../components/LoaderFull'

import { AuthActions } from '../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import ReCAPTCHA from 'react-google-recaptcha'
import { captchaKey } from '../../services/captcha'

window.recaptchaOptions = {
    lang: 'pt-BR'
}

class LoginPage extends Component {
    state = {
        email: '',
        password: '',
        msgErroEmail: '',
        msgErroPassword: '',
        loading: false,
        bgIndex: 1,
        captcha: '',
        captchaError: false
    }

    render() {
        const { email, password, msgErroEmail, msgErroPassword } = this.state
        const { msg } = this.props.error

        return (
            <PageWrapper>
                <Helmet>
                    <title>Login - JCC Ouvidoria</title>
                </Helmet>
                <LoaderFull visible={this.props.loading} />
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="bg-container-login">
                        <div className={`bg-item ${this.state.bgIndex === 1 ? 'active' : ''}`}>
                            <img src={require('../../assets/images/bg-intro-1.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 2 ? 'active' : ''}`}>
                            <img src={require('../../assets/images/bg-intro-4.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 3 ? 'active' : ''}`}>
                            <img src={require('../../assets/images/bg-intro-5.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 4 ? 'active' : ''}`}>
                            <img src={require('../../assets/images/bg-intro-6.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 5 ? 'active' : ''}`}>
                            <img src={require('../../assets/images/bg-intro-7.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 6 ? 'active' : ''}`}>
                            <img src={require('../../assets/images/bg-intro-8.jpg')} />
                        </div>
                    </div>
                    <div className={`container-login-page`}>
                        <div className="container-card">
                            <BorderCard corBorda="transparent">
                                <div className="conteudo-card-login">
                                    <div className="img-arvore">
                                        <img src={arvore} alt="" />
                                    </div>
                                    <div className="titulo">Realize seu login e acompanhe suas ocorrências</div>
                                    <div className="input-item">
                                        <TextInput
                                            value={email}
                                            labelInput="E-mail"
                                            msgErro={msgErroEmail}
                                            onChange={e => this.setState({ email: e.target.value })}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <PasswordInput
                                            value={password}
                                            labelInput="Senha"
                                            msgErro={msgErroPassword}
                                            onChange={e => this.setState({ password: e.target.value })}
                                        />
                                    </div>

                                    <ReCAPTCHA
                                        ref={ref => (this.recaptchaRef = ref)}
                                        sitekey={captchaKey}
                                        size="invisible"
                                        tabindex="9999999999"
                                        onErrored={error => this.setState({ captchaError: true })}
                                        badge="inline"
                                    />

                                    <div style={{ marginTop: 20 }}>
                                        <MessageBox mensagemErro={msg} />
                                    </div>

                                    <div className="buttons-container">
                                        <div className="button-item">
                                            <Button onClick={this.handleSubmit} color="primary">
                                                ENTRAR
                                            </Button>
                                        </div>
                                        <div className="button-item">
                                            <UnderlinedLink link="/recuperar-senha" textoLink="Esqueci minha senha" />
                                        </div>
                                    </div>
                                </div>
                            </BorderCard>
                        </div>
                    </div>
                </ContentWrapper>

                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = () => {
        this.bgInterval = setInterval(
            () =>
                this.setState({
                    bgIndex: this.state.bgIndex < 6 ? this.state.bgIndex + 1 : 1
                }),
            5000
        )
    }

    componentWillUnmount = () => {
        clearInterval(this.bgInterval)
    }

    componentDidUpdate = () => {
        if (this.props.user) this.props.history.push('/')
    }

    handleSubmit = e => {
        e.preventDefault()

        const { captchaError } = this.state

        if (this.recaptchaRef.current) {
            this.recaptchaRef.current.execute()
        }

        if (captchaError) return

        let msgErroEmail = ''
        let msgErroPassword = ''

        this.props.loginError('')

        if (!this.isEmailValid()) msgErroEmail = 'Email e senha são obrigatórios'

        if (this.state.password.length < 8) msgErroPassword = 'A senha deve ter ao menos 8 caractéres'

        this.setState({ msgErroEmail, msgErroPassword }, () => {
            if (!msgErroEmail && !msgErroPassword) this.props.loginUser(this.state.email, this.state.password)
        })
    }

    isEmailValid = () => {
        const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+$/
        return regex.test(this.state.email)
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user,
    error: auth.error,
    loading: auth.loading
})

const mapDispatchToProps = dispatch => bindActionCreators({ ...AuthActions }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage)
