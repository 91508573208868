import React, { Component } from "react";
import "./style.scss";

export default class OverlayCard extends Component {
    render() {
        return (
            <div
                className="overlay-card"
                style={{
                    width: this.props.width,
                    height: this.props.height,
                    borderColor: this.props.corBorda,
                }}
            >
                <div className="foto-card">
                    <img src={this.props.caminhoFoto} alt="" />
                </div>
                <div className="overlay-container">{this.props.children}</div>
            </div>
        );
    }
}
