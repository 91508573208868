import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import BorderCard from '../../components/Cards/BorderCard'
import CabecalhoListagem from '../../components/CabecalhoListagem'
import ListagemItem from '../../components/ListagemItem'
import TextInput from '../../components/Inputs/TextInput'
import TextInputMask from '../../components/Inputs/TextInputMask'
import SelectInput from '../../components/Inputs/SelectInput'
import Button from '../../components/Button/Button'
import MessageBox from '../../components/MessageBox'
import Modal from '../../components/Modal'
import ModalSuccess from '../../components/ModalSuccess'
import ModalConfirmation from '../../components/ModalConfirmation'
import LoaderFull from '../../components/LoaderFull'
import api, { baseURL } from '../../services/api'
import { Helmet } from 'react-helmet'

const statusArray = [{ id: 1, name: 'Ativo' }, { id: 0, name: 'Inativo' }]

const rolesArray = [{ id: 2, name: 'Admin' }, { id: 1, name: 'Super Admin' }]

export default class GestaoAdministradores extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showEdit: false,
            loading: false,
            mensagemErro: '',
            errors: {},
            items: [],

            first_name: '',
            last_name: '',
            email: '',
            status: '1',
            role: 2,
            editId: 0,
            cellphone: '',

            password: '',
            password_confirmation: '',

            modalTitle: '',
            modalMessage: '',
            formError: '',

            notEditable: []
        }

        this.modalSuccessRef = React.createRef()
        this.modalConfirmationRef = React.createRef()
    }

    render() {
        const {
            items,
            mensagemErro,
            errors,
            editId,
            formError,
            notEditable,
            first_name,
            last_name,
            email,
            cellphone,
            status,
            role,
            password,
            password_confirmation
        } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>Administradores - JCC Ouvidoria</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <Modal title={this.state.modalTitle} ref={this.modalSuccessRef}>
                    <ModalSuccess text={this.state.modalMessage} />
                </Modal>
                <Modal title="Excluir item" ref={this.modalConfirmationRef}>
                    <ModalConfirmation
                        text="Tem certeza que deseja excluir este item?"
                        onYes={this.handleDelete}
                        onNo={() => this.closeModal(this.modalConfirmationRef)}
                    />
                </Modal>
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="admin-gestao-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="titulo-gestao">
                                        Administradores
                                        <div className="button-item">
                                            <Button color="primary" onClick={() => this.openEdit(null)}>
                                                Adicionar
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showEdit ? 'block' : 'none' }}>
                                        <div className="container-gestao-edicao">
                                            <div className="input-line">
                                                <div className="input-item">
                                                    <TextInput
                                                        labelInput="Nome"
                                                        value={first_name}
                                                        onChange={e => this.setState({ first_name: e.target.value })}
                                                        msgErro={errors.first_name}
                                                    />
                                                </div>
                                                <div className="input-item">
                                                    <TextInput
                                                        labelInput="Sobrenome"
                                                        value={last_name}
                                                        onChange={e => this.setState({ last_name: e.target.value })}
                                                        msgErro={errors.last_name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-line">
                                                <div className="input-item">
                                                    <TextInput
                                                        labelInput="Email"
                                                        value={email}
                                                        onChange={e => this.setState({ email: e.target.value })}
                                                        msgErro={errors.email}
                                                    />
                                                </div>
                                                <div className="input-item">
                                                    <TextInputMask
                                                        labelInput="Telefone"
                                                        value={cellphone}
                                                        onChange={e => this.setState({ cellphone: e.target.value })}
                                                        msgErro={errors.cellphone}
                                                        mask={'(99) 999999999'}
                                                        isIconRight={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="input-line">
                                                <div className="input-item">
                                                    <SelectInput
                                                        labelInput="Cargo"
                                                        value={role}
                                                        defaultValue={role || 1}
                                                        options={rolesArray}
                                                        onChange={e => this.setState({ role: e.target.value })}
                                                    />
                                                </div>
                                                <div className="input-item">
                                                    <SelectInput
                                                        labelInput="Status"
                                                        value={status}
                                                        defaultValue={status || '1'}
                                                        options={statusArray}
                                                        onChange={e => this.setState({ status: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            {!editId && (
                                                <div className="input-line">
                                                    <div className="input-item">
                                                        <TextInput
                                                            labelInput="Senha"
                                                            value={password}
                                                            onChange={e => this.setState({ password: e.target.value })}
                                                            msgErro={errors.password}
                                                            type="password"
                                                        />
                                                    </div>
                                                    <div className="input-item">
                                                        <TextInput
                                                            labelInput="Senha"
                                                            value={password_confirmation}
                                                            onChange={e =>
                                                                this.setState({ password_confirmation: e.target.value })
                                                            }
                                                            msgErro={errors.password_confirmation}
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                <MessageBox mensagemErro={formError} />
                                            </div>
                                            <div className="container-botoes-submit">
                                                <div className="button-item">
                                                    <Button color="primary" onClick={this.handleSubmit}>
                                                        Salvar
                                                    </Button>
                                                </div>
                                                {editId > 0 && (
                                                    <div className="button-item">
                                                        <Button
                                                            color="primary"
                                                            onClick={() => this.openModal(this.modalConfirmationRef)}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </div>
                                                )}
                                                <div className="button-item">
                                                    <Button color="primary" outline onClick={this.closeEdit}>
                                                        Cancelar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="listagem-container">
                                        <CabecalhoListagem items={['Nome', 'Email', 'Status']} />
                                        {mensagemErro ? (
                                            <div className="msg-sem-items">{mensagemErro}</div>
                                        ) : (
                                            items.map((item, index) => (
                                                <ListagemItem
                                                    key={index}
                                                    admin={true}
                                                    onClick={() => this.openEdit(item)}
                                                    name={`${item.first_name} ${item.last_name}`}
                                                    email={item.email}
                                                    status={item.status == 1 ? 'Ativo' : 'Inativo'}
                                                    notEditable={notEditable.some(i => i === item.id)}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {/* <Pagination/> */}
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = async () => {
        const { endpoint, plural, configFilter } = this.props

        try {
            this.setState({ loading: true, mensagemErro: '' })

            const response = await api.get(`${baseURL}administrators?order_by=first_name&order=ASC`)
            const items = response.data.data.items

            this.setState({ loading: false, items, mensagemErro: items.length ? '' : 'Não existem itens cadastrados.' })
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    openModal = modalRef => modalRef.current.openModal()

    closeModal = modalRef => modalRef.current.closeModal()

    openEdit = item => {
        this.setState({
            showEdit: true,
            first_name: item ? item.first_name : '',
            last_name: item ? item.last_name : '',
            email: item ? item.email : '',
            status: item ? item.status : 1,
            role: item ? item.roles[0].role_id : 2,
            editId: item ? item.id : 0,
            cellphone: item ? item.cellphone : '',
            formError: '',
            password: '',
            password_confirmation: ''
        })
    }

    closeEdit = () => {
        this.setState({
            showEdit: false,
            first_name: '',
            last_name: '',
            email: '',
            status: 1,
            role: 2,
            editId: 0,
            cellphone: '',
            formError: '',
            password: '',
            password_confirmation: ''
        })
    }

    validateFields = () => {
        const { first_name, last_name, cellphone, password, password_confirmation } = this.state

        let errors = {}

        if (!first_name || first_name.length < 3) errors.first_name = 'Nome deve ter no mínimo 3 caracteres.'
        if (last_name && last_name.length < 3) errors.last_name = 'Sobrenome deve ter no mínimo 3 caracteres.'
        if (!this.isEmailValid()) errors.email = 'Email inválido'
        if (!cellphone || cellphone.replace(/[^\d]/g, '').length < 9) errors.cellphone = 'Telefone inválido'
        if (password.length > 0 && password.length < 8) errors.password = 'A senha deve ter ao menos 8 caractéres'
        if (password_confirmation !== password) errors.password_confirmation = 'As senhas não correspondem'

        this.setState({ errors })

        return JSON.stringify(errors) === '{}'
    }

    handleSubmit = async () => {
        try {
            if (!this.validateFields()) return

            const {
                editId,
                first_name,
                last_name,
                email,
                cellphone,
                status,
                role,
                password,
                password_confirmation
            } = this.state
            const { title, endpoint, plural } = this.props

            this.setState({ loading: true, mensagemErro: '' })

            let modalTitle = ''
            let modalMessage = ''

            if (editId) {
                const admin = this.state.items.find(item => item.id === editId)
                const celphoneFiltered = cellphone.replace(/[^\d]/g, '')

                let send = {}

                if (admin.first_name !== first_name) send.first_name = first_name
                if (admin.last_name !== last_name) send.last_name = last_name
                if (admin.email !== email) send.email = email
                if (admin.cellphone !== celphoneFiltered) send.cellphone = celphoneFiltered
                if (admin.roles[0].role_id !== role) send.role = role
                if (admin.status !== status) send.status = status

                await api.put(`${baseURL}administrator/${editId}`, send)
                modalTitle = `${admin.first_name} ${admin.last_name}`
                modalMessage = `Administrador alterado com sucesso.`
            } else {
                const send = {
                    first_name,
                    last_name,
                    email,
                    cellphone: cellphone.replace(/[^\d]/g, ''),
                    status,
                    role,
                    password,
                    password_confirmation
                }
                await api.post(`${baseURL}administrator`, send)
                modalTitle = `Novo Administrador`
                modalMessage = `Administrador adicionado com sucesso.`
            }

            const response = await api.get(`${baseURL}administrators?order_by=first_name&order=ASC`)
            const items = response.data.data.items

            this.closeEdit()
            this.setState({ items, modalTitle, modalMessage })
            this.openModal(this.modalSuccessRef)
        } catch (error) {
            console.log('error', error)
            let formError = ''
            let mensagemErro = ''

            if (!error.response || error.response.status === 500)
                mensagemErro = 'Ocorreu um erro inesperado. Tente novamente mais tarde'
            else if (error.response.status === 422) {
                if (error.response.data.errors.email) formError = 'E-mail já está em uso.'
            }

            this.setState({ mensagemErro, formError })
        } finally {
            this.setState({ loading: false })
        }
    }

    handleDelete = async () => {
        const { editId } = this.state
        const { endpoint, plural } = this.props

        try {
            this.closeModal(this.modalConfirmationRef)

            this.setState({ loading: true, mensagemErro: '', formError: '' })

            await api.delete(`${baseURL}administrator/${editId}`)

            const response = await api.get(`${baseURL}administrators?order_by=first_name&order=ASC`)

            const items = response.data.data.items

            this.closeEdit()
            this.setState({
                items,
                modalTitle: `Excluir Administrador`,
                modalMessage: `Administrador excluído com sucesso.`
            })
            this.openModal(this.modalSuccessRef)
        } catch (error) {
            console.log('error', error)
            let formError = ''
            let mensagemErro = ''

            if (!error.response || error.response.status === 500)
                mensagemErro = 'Ocorreu um erro inesperado. Tente novamente mais tarde'
            else if (error.response.status === 424)
                formError = 'Você deve migrar todas as ocorrências dessa entidade antes de removê-la.'

            this.setState({ mensagemErro, formError })
        } finally {
            this.setState({ loading: false })
        }
    }

    isEmailValid = () => {
        const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+$/
        return regex.test(this.state.email)
    }
}
