import React, { Component } from 'react'
import bg from '../../assets/images/bg-2.jpg'
import ShadowCard from '../../components/Cards/ShadowCard'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import { Helmet } from 'react-helmet'
import TicketTopInfo from '../../components/TicketTopInfo/TicketTopInfo'
import TicketInputListing from '../../components/TicketInputListing/TicketInputListing'
import AttachmentListing from '../../components/AttachmentListing/AttachmentListing'
import DenouncedInfo from '../../components/DenouncedInfo/DenouncedInfo'
import MessagesListing from '../../components/MessagesListing/MessagesListing'
import InternalComment from '../../components/InternalComment/InternalComment'
import ReplyField from '../../components/ReplyField/ReplyField'
import './TicketPage.scss'
import axios from 'axios'
import api, { baseURL } from '../../services/api'
import { connect } from 'react-redux'
import LoaderFull from '../../components/LoaderFull'
import Modal from '../../components/Modal'
import ModalConfirmation from '../../components/ModalConfirmation'
import ModalEditText from '../../components/ModalEditText'

class TicketPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            occurrence: {},
            reply: '',
            teamReply: '',
            loading: false,
            errorMessage: '',
            errorEditMessage: '',
            modalErrorMessage: '',
            allowReply: true,
            deadline: '',
            showDatePicker: false,
            currentMall: 1,
            currentSector: 1,
            currentStatus: 1,
            currentTopic: 1,
            currentType: 1,
            selectedId: null,
            editText: '',
            editInfo: { name: '', date: '' }
        }

        this.modalEditInternalCommentRef = React.createRef()
        this.modalDeleteInternalCommentRef = React.createRef()
        this.modalEditReplyRef = React.createRef()
        this.modalDeleteReplyRef = React.createRef()

        this.modalConfirmationRef = React.createRef()
        this.modalErrorRef = React.createRef()
        this.modalDeleteReportRef = React.createRef()
    }

    render() {
        const { occurrence } = this.state

        const isFinished = occurrence.substatus > 2

        return (
            <div>
                <PageWrapper>
                    <Helmet>
                        <title>{`Protocolo ${occurrence.protocol_number || ''} - JCC Ouvidoria`}</title>
                    </Helmet>

                    <LoaderFull visible={this.state.loading} />

                    <Modal title={`Deseja alterar as informações dessa ocorrência?`} ref={this.modalConfirmationRef}>
                        <ModalConfirmation
                            onYes={this.sendReply}
                            onNo={() => this.closeModal(this.modalConfirmationRef)}
                        />
                    </Modal>
                    <Modal title={`Deseja remover comentário interno?`} ref={this.modalDeleteInternalCommentRef}>
                        <ModalConfirmation
                            onYes={() => this.deleteNote(this.state.selectedId)}
                            onNo={() => this.closeModal(this.modalDeleteInternalCommentRef)}
                        />
                    </Modal>
                    <Modal title={`Deseja remover réplica de administrador?`} ref={this.modalDeleteReplyRef}>
                        <ModalConfirmation
                            onYes={() => this.deleteReply(this.state.selectedId)}
                            onNo={() => this.closeModal(this.modalDeleteReplyRef)}
                        />
                    </Modal>
                    <Modal title={`Deseja excluir relatório final?`} ref={this.modalDeleteReportRef}>
                        <ModalConfirmation
                            onYes={() => this.deleteReport(this.state.selectedId)}
                            onNo={() => this.closeModal(this.modalDeleteReportRef)}
                        />
                    </Modal>

                    <ModalEditText
                        ref={this.modalEditInternalCommentRef}
                        onYes={() => this.editNote(this.state.selectedId)}
                        onNo={() => this.closeModal(this.modalEditInternalCommentRef)}
                        editText={this.state.editText}
                        editInfo={this.state.editInfo}
                        onChange={e => this.setState({ editText: e.target.value })}
                        errorMessage={this.state.errorEditMessage}
                    />

                    <ModalEditText
                        ref={this.modalEditReplyRef}
                        onYes={() => this.editReply(this.state.selectedId)}
                        onNo={() => this.closeModal(this.modalEditReplyRef)}
                        editText={this.state.editText}
                        editInfo={this.state.editInfo}
                        onChange={e => this.setState({ editText: e.target.value })}
                        errorMessage={this.state.errorEditMessage}
                    />

                    <Modal title={'Erro'} ref={this.modalErrorRef}>
                        <div>{this.state.modalErrorMessage}</div>
                    </Modal>

                    <ContentWrapper>
                        <ResponsiveHeader />
                        <div className="page-container">
                            <div className="page-container__bg-container">
                                <img className="page-container__bg" src={bg} alt="" />
                            </div>

                            <div className="container padding-vertical--bigger">
                                <ShadowCard>
                                    <div className="new-ticket-page-container">
                                        <TicketTopInfo
                                            onGoBackClick={() => this.props.history.goBack()}
                                            protocolNumber={occurrence.protocol_number}
                                            customer={occurrence.customer}
                                            creationDate={this.formatDate(occurrence.created_at, true)}
                                            expirationDate={this.formatDate(occurrence.expiration_date, true)}
                                            lastModificationDate={this.formatDate(occurrence.updated_at, true)}
                                            statusData={this.getSelectData('statusArray', 'currentStatus')}
                                        />
                                        <div className="main-container">
                                            <div className="main-column">
                                                <div className="title">Narrativa dos fatos</div>
                                                <TicketInputListing
                                                    mallData={this.getSelectData('mallsArray', 'currentMall')}
                                                    sectorData={this.getSelectData('sectorsArray', 'currentSector')}
                                                    topicData={this.getSelectData('topicsArray', 'currentTopic')}
                                                    typeData={this.getSelectData('typesArray', 'currentType')}
                                                    isFinished={isFinished}
                                                />
                                                <div className="ticket-info-listing">
                                                    <DenouncedInfo
                                                        denouncedName={occurrence.denounced}
                                                        description={occurrence.narrative}
                                                    />
                                                    <AttachmentListing
                                                        data={
                                                            occurrence.attachments
                                                                ? occurrence.attachments.filter(item => item.type !== 4)
                                                                : []
                                                        }
                                                    />
                                                </div>

                                                <MessagesListing
                                                    data={this.getReplies()}
                                                    onEditAdminReply={this.setEditReply}
                                                    onDeleteAdminReply={this.setDeleteReply}
                                                    attachments={this.props.attachments}
                                                />

                                                <ReplyField
                                                    onCheckboxClick={() =>
                                                        this.setState({ allowReply: !this.state.allowReply })
                                                    }
                                                    allowReply={this.state.allowReply}
                                                    onTextChange={e => this.setState({ reply: e.target.value })}
                                                    onButtonClick={this.handleSubmit}
                                                    textValue={this.state.reply}
                                                    msgError={this.state.errorMessage}
                                                    deadline={this.state.deadline}
                                                    onDeadlineChange={e =>
                                                        this.setState({
                                                            deadline: e.target.value
                                                        })
                                                    }
                                                    handleDayChange={this.handleDayChange}
                                                    statusData={this.getSelectData('statusArray', 'currentStatus')}
                                                    isFinished={isFinished}
                                                />
                                            </div>
                                            <div className="aside-column">
                                                <InternalComment
                                                    notes={this.getNotes()}
                                                    reports={occurrence.reports}
                                                    textValue={this.state.teamReply}
                                                    onTextChange={e => this.setState({ teamReply: e.target.value })}
                                                    onSendNote={this.sendNote}
                                                    onSendReport={this.sendReport}
                                                    onEditNote={this.setEditNote}
                                                    onDeleteNote={this.setDeleteNote}
                                                    onDeleteReport={this.setDeleteReport}
                                                    isLocked={occurrence.substatus == 3 || occurrence.substatus == 4}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ShadowCard>
                            </div>
                        </div>
                    </ContentWrapper>
                </PageWrapper>
            </div>
        )
    }

    componentDidMount = async () => {
        try {
            this.setState({ loading: true, errorMessage: '' })

            const requests = await axios.all([
                api.get(`${baseURL}occurrence/${this.props.match.params.id}`),
                api.get(`${baseURL}malls?per_page=-1`),
                api.get(`${baseURL}sectors?per_page=-1`),
                api.get(`${baseURL}status?per_page=-1`),
                api.get(`${baseURL}occurrence-topics?per_page=-1`),
                api.get(`${baseURL}occurrence-types?per_page=-1`)
            ])

            const occurrence = requests[0].data.data
            const mallsArray = requests[1].data.data.items
            const sectorsArray = requests[2].data.data.items
            const statusArray = requests[3].data.data.items
            const topicsArray = requests[4].data.data.items
            const typesArray = requests[5].data.data.items

            let deadline = ''

            if (occurrence.expiration_date) {
                const date = occurrence.expiration_date

                const day = date.substr(8, 2)
                const month = date.substr(5, 2)
                const year = date.substr(0, 4)

                deadline = `${day}/${month}/${year}`
            }

            this.setState({
                loading: false,
                occurrence,
                deadline,
                mallsArray,
                sectorsArray,
                statusArray,
                topicsArray,
                typesArray,
                currentMall: occurrence.mall.id,
                currentSector: occurrence.sector.id,
                currentStatus: occurrence.status.id,
                currentTopic: occurrence.topic.id,
                currentType: occurrence.type.id
            })
        } catch (error) {
            console.log('error', error)
            this.setState({
                loading: false,
                errorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
            })
        }
    }

    getSelectData = (array, key) => {
        return {
            options: this.state[array],
            value: this.state[key],
            onChange: e => this.setState({ [key]: e.target.value })
        }
    }

    getReplies = () => {
        if (!this.state.occurrence.replies) return []

        return this.state.occurrence.replies.sort(function(a, b) {
            return a.created_at.localeCompare(b.created_at)
        })
    }

    getNotes = () => {
        return this.state.occurrence.notes
    }

    isLocked = () => {
        return this.state.currentStatus === '3' || this.state.currentStatus === '4'
    }

    formatDate = (date, hideHours) => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hour = date.substr(10, 6)

        if (hideHours) return `${day}/${month}/${year}`

        return `${day}/${month}/${year} às ${hour}`
    }

    handleDayChange = date => {
        let year = date.getFullYear(0, 4).toString()
        let month = (date.getMonth() + 1).toString()
        let day = date.getDate().toString()

        if (month.length === 1) month = '0' + month

        if (day.length === 1) day = '0' + day

        if (new Date(`${year}-${month}-${day}`) < new Date()) {
            this.setState({
                errorMessage: 'A data de expiração deve ser posterior ao dia atual.',
                showDatePicker: !this.state.showDatePicker
            })
            return
        }

        const formatted = `${day}/${month}/${year}`

        this.setState({
            deadline: formatted,
            showDatePicker: !this.state.showDatePicker,
            errorMessage: ''
        })
    }

    handleSubmit = () => {
        const {
            reply,
            currentMall,
            currentSector,
            currentStatus,
            currentTopic,
            currentType,
            occurrence,
            deadline
        } = this.state

        if (reply && reply.length < 20) {
            this.setState({ errorMessage: 'O conteúdo da réplica deve ter no mínimo 20 caracteres.' })
            return
        }

        let enableModal = false

        if (currentMall !== occurrence.mall.id) enableModal = true
        else if (currentSector !== occurrence.sector.id) enableModal = true
        else if (currentStatus !== occurrence.status.id) enableModal = true
        else if (currentTopic !== occurrence.topic.id) enableModal = true
        else if (currentType !== occurrence.type.id) enableModal = true

        if (enableModal) this.openModal(this.modalConfirmationRef)
        else this.sendReply()
    }

    sendNote = async () => {
        try {
            this.setState({ loading: true, errorMessage: '' })

            const send = {
                content: this.state.teamReply,
                user_id: this.props.user.id,
                occurrence_id: this.state.occurrence.id
            }

            await api.post(`${baseURL}note`, send)

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)

            this.setState({ occurrence: response.data.data, teamReply: '' })
        } catch (error) {
            console.log(error)
            this.setState({ errorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        } finally {
            this.setState({ loading: false })
        }
    }

    setEditNote = (selectedId, editText, editInfo) => {
        this.setState({ selectedId, editText, editInfo, errorEditMessage: '' })
        this.openModal(this.modalEditInternalCommentRef)
    }

    editNote = async id => {
        this.setState({ errorEditMessage: '' })

        if (this.state.editText.length < 20) {
            this.setState({ errorEditMessage: 'O conteúdo deve ter ao menos 20 caractéres' })
            return
        }

        try {
            this.closeModal(this.modalEditInternalCommentRef)

            this.setState({ loading: true, modalErrorMessage: '' })

            await api.put(`${baseURL}note/${id}`, { content: this.state.editText })

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)

            this.setState({ occurrence: response.data.data, teamReply: '' })
        } catch (error) {
            console.log(error)
            this.openModal(this.modalErrorRef)
            this.setState({ modalErrorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        } finally {
            this.setState({ loading: false })
        }
    }

    setDeleteNote = id => {
        this.setState({ selectedId: id })
        this.openModal(this.modalDeleteInternalCommentRef)
    }

    deleteNote = async id => {
        try {
            this.closeModal(this.modalDeleteInternalCommentRef)

            this.setState({ loading: true, modalErrorMessage: '' })

            await api.delete(`${baseURL}note/${id}`)

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)

            this.setState({ occurrence: response.data.data, teamReply: '' })
        } catch (error) {
            console.log(error)
            this.openModal(this.modalErrorRef)
            this.setState({ modalErrorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        } finally {
            this.setState({ loading: false })
        }
    }

    sendReport = e => {
        const files = [...e.target.files]

        files.forEach((item, index) => {
            const reader = new FileReader()
            const file = e.target.files[index]

            reader.readAsDataURL(file)
            reader.onerror = error => console.log(error)
            reader.onload = async upload => {
                try {
                    this.setState({ loading: true, errorMessage: '' })

                    const formData = new FormData()
                    formData.append('file', file)
                    formData.append('type', 4)

                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    const responseFile = await api.post(`${baseURL}attachment`, formData, config)

                    const responseReport = await api.put(`${baseURL}occurrence/${this.props.match.params.id}`, {
                        attachments: responseFile.data.data.id
                    })

                    this.setState({ occurrence: responseReport.data.data })
                } catch (error) {
                    console.log(error)
                    this.setState({ errorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
                } finally {
                    this.setState({ loading: false })
                }
            }
        })
    }

    setDeleteReport = id => {
        this.setState({ selectedId: id })
        this.openModal(this.modalDeleteReportRef)
    }

    deleteReport = async id => {
        try {
            this.closeModal(this.modalDeleteReportRef)

            this.setState({ loading: true, modalErrorMessage: '' })

            await api.delete(`${baseURL}attachment/${id}`)

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)

            this.setState({ occurrence: response.data.data, teamReply: '' })
        } catch (error) {
            console.log(error)
            this.openModal(this.modalErrorRef)
            this.setState({ modalErrorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        } finally {
            this.setState({ loading: false })
        }
    }

    sendReply = async () => {
        this.closeModal(this.modalConfirmationRef)

        const {
            reply,
            currentMall,
            currentSector,
            currentStatus,
            currentTopic,
            currentType,
            occurrence,
            allowReply,
            deadline
        } = this.state

        const lockOccurrence = this.isLocked()

        let sendUpdate = {}

        if (currentMall !== occurrence.mall.id) sendUpdate.mall_id = currentMall
        if (currentSector !== occurrence.sector.id) sendUpdate.sector_id = currentSector
        if (currentStatus !== occurrence.status.id) sendUpdate.status_id = currentStatus
        if (currentTopic !== occurrence.topic.id) sendUpdate.topic_id = currentTopic
        if (currentType !== occurrence.type.id) sendUpdate.type_id = currentType

        if (lockOccurrence) {
            sendUpdate.allow_reply = false
            const date = new Date(Date.now())

            let day = date.getDate().toString()
            let month = (date.getMonth() + 1).toString()
            let year = date.getFullYear().toString()

            if (day.length === 1) day = '0' + day
            if (month.length === 1) month = '0' + month

            sendUpdate.expiration_date = `${year}-${month}-${day}`
        } else {
            sendUpdate.allow_reply = allowReply ? 1 : 0
            if (deadline && allowReply) {
                const dateArray = deadline.split('/')
                sendUpdate.expiration_date = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
            } else sendUpdate.expiration_date = null
        }

        try {
            this.setState({ loading: true, errorMessage: '' })

            if (reply) await api.post(`${baseURL}occurrence/${this.props.match.params.id}/reply`, { content: reply })

            if (JSON.stringify(sendUpdate) !== '{}')
                await api.put(`${baseURL}occurrence/${this.props.match.params.id}`, sendUpdate)

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)
            const occurrence = response.data.data

            this.setState({
                loading: false,
                occurrence,
                reply: '',
                teamReply: '',
                deadline: null
            })

            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        } catch (error) {
            console.log(error)
            this.setState({
                loading: false,
                errorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
            })
        }
    }

    setEditReply = (selectedId, editText, editInfo) => {
        this.setState({ selectedId, editText, editInfo, errorEditMessage: '' })
        this.openModal(this.modalEditReplyRef)
    }

    editReply = async id => {
        this.setState({ errorEditMessage: '' })

        if (this.state.editText.length < 20) {
            this.setState({ errorEditMessage: 'O conteúdo deve ter ao menos 20 caractéres' })
            return
        }

        try {
            this.closeModal(this.modalEditReplyRef)

            this.setState({ loading: true, modalErrorMessage: '' })

            await api.put(`${baseURL}reply/${id}`, {
                content: this.state.editText
            })

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)

            this.setState({ occurrence: response.data.data, teamReply: '' })
        } catch (error) {
            console.log(error)
            this.openModal(this.modalErrorRef)
            this.setState({ modalErrorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        } finally {
            this.setState({ loading: false })
        }
    }

    setDeleteReply = id => {
        this.setState({ selectedId: id })
        this.openModal(this.modalDeleteReplyRef)
    }

    deleteReply = async id => {
        try {
            this.closeModal(this.modalDeleteReplyRef)

            this.setState({ loading: true, modalErrorMessage: '' })

            await api.delete(`${baseURL}reply/${id}`)

            const response = await api.get(`${baseURL}occurrence/${this.props.match.params.id}`)

            this.setState({ occurrence: response.data.data, teamReply: '' })
        } catch (error) {
            console.log(error)
            this.openModal(this.modalErrorRef)
            this.setState({ modalErrorMessage: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        } finally {
            this.setState({ loading: false })
        }
    }

    openModal = modalRef => modalRef.current.openModal()
    closeModal = modalRef => modalRef.current.closeModal()
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

export default connect(mapStateToProps)(TicketPage)
