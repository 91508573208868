import React from 'react'
import style from './Title.module.scss'
import TitleGoBackButton from './TitleGoBackButton'

const TitleClient = props => {
    return (
        <div>
            <TitleGoBackButton goBackText={props.goBackText} goBackClick={props.goBackClick} />
            <div className={style.TitleClientContainer}>
                <h3>{props.content}</h3>
                <div className={style.occurrences}>
                    <h3>{props.occurrences}&nbsp;</h3>
                    <span>{'Occorrência' + (props.occurrences !== 1 ? 's' : '')}</span>
                </div>
            </div>
        </div>
    )
}

export default TitleClient
