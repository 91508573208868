import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from "./Title.module.scss";

const TitleGoBackButton = props => {

    return (
        <button className={style.TitleGoBackButtonContainer} onClick={props.goBackClick}>
            <div className={style.icone}><FontAwesomeIcon icon="chevron-left" /></div>
            {props.goBackText}
        </button>
    )
};

export default TitleGoBackButton;
