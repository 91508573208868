import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import CabecalhoListagem from '../../components/CabecalhoListagem'
import ListagemItem from '../../components/ListagemItem'
import BoxInfoAdmin from '../../components/BoxInfoAdmin'
import Pagination from '../../components/Pagination'

import LoaderFull from '../../components/LoaderFull'
import api, { baseURL } from '../../services/api'
import axios from 'axios'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

class AdminPanel extends Component {
    constructor(props) {
        super(props)
        var { timeBlack, timeGray } = this.getTimeString()
        this.state = {
            timeBlack,
            timeGray,
            occurrences: [],
            loading: false,
            mensagemErro: '',
            pageCount: 0,
            scoreboard: [0, 0, 0, 0]
        }
    }

    render() {
        const { occurrences, mensagemErro, timeBlack, timeGray, scoreboard } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>Início - JCC Ouvidoria</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="painel-admin-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="topo-card">
                                        <div className="coluna">
                                            <div className="info-data">
                                                {timeBlack}
                                                <span className="cinza">{timeGray}</span>
                                            </div>
                                        </div>

                                        <div className="coluna">
                                            <div className="info-data">
                                                Último Login:{' '}
                                                <span className="cinza">
                                                    {this.formatDateWithHours(this.props.user.last_login)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <BoxInfoAdmin scoreboard={scoreboard} />

                                    <div className="titulo">Novas ocorrências</div>

                                    <div className="container-listagem">
                                        <CabecalhoListagem
                                            items={[
                                                'Protocolo',
                                                'Usuário',
                                                'Local',
                                                'Data de criação',
                                                'Última atualização',
                                                'Situação',
                                                'Status'
                                            ]}
                                        />
                                        {mensagemErro ? (
                                            <div className="msg-sem-ocorrencias msg-erro-painel">{mensagemErro}</div>
                                        ) : (
                                            occurrences.map((item, index) => (
                                                <ListagemItem
                                                    key={index}
                                                    onClick={() =>
                                                        this.props.history.push(`/admin-ocorrencia/${item.id}`)
                                                    }
                                                    valorProtocolo={item.protocol_number}
                                                    valorLocal={item.mall.name}
                                                    valorCriacao={this.formatDate(item.created_at)}
                                                    valorAtt={this.formatDate(item.updated_at)}
                                                    valorStatus={item.status && item.status.name}
                                                    valorSituacao={item.substatus}
                                                    valorUsuario={item.customer ? item.customer.first_name : ''}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {this.state.pageCount > 1 && (
                                        <Pagination
                                            pageCount={this.state.pageCount}
                                            onPageChange={data => this.loadOccurrences(data.selected + 1)}
                                        />
                                    )}
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = async () => {
        this.loadOccurrences(1)

        const _this = this

        this.timer = setInterval(function() {
            const { timeBlack, timeGray } = _this.getTimeString()
            _this.setState({ timeBlack, timeGray })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    loadOccurrences = async page => {
        try {
            this.setState({ loading: true, mensagemErro: '' })

            const requests = await axios.all([
                api.get(`${baseURL}occurrences?order=desc&substatus=0&current_page=${page}`),
                api.get(`${baseURL}occurrence/scoreboard`)
            ])

            const occurrences = requests[0].data.data.items
            const scoreboardArray = requests[1].data.data.items

            let scoreboard = []

            for (let i = 0; i < 4; i++) {
                const item = scoreboardArray.find(item => item.status == i)
                item ? scoreboard.push(item.total) : scoreboard.push(0)
            }

            let pageCount = requests[0].data.data.totals / 20
            if (Number.isInteger(pageCount)) pageCount = Number.parseInt(pageCount) + 1

            this.setState({
                loading: false,
                pageCount,
                scoreboard,
                occurrences,
                mensagemErro: occurrences.length ? '' : 'Não existem ocorrências cadastradas.'
            })
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    getTimeString() {
        const dateNow = new Date(Date.now())

        let hours = dateNow.getHours().toString()
        let minutes = dateNow.getMinutes().toString()
        let day = dateNow.getDate().toString()
        let weekNumber = dateNow.getDay()
        let monthNumber = dateNow.getMonth()
        let year = dateNow.getFullYear()

        let weekday = ''
        if (weekNumber === 0) weekday = 'Domingo'
        else if (weekNumber === 1) weekday = 'Segunda'
        else if (weekNumber === 2) weekday = 'Terça'
        else if (weekNumber === 3) weekday = 'Quarta'
        else if (weekNumber === 4) weekday = 'Quinta'
        else if (weekNumber === 5) weekday = 'Sexta'
        else if (weekNumber === 6) weekday = 'Sábado'

        let month = ''
        if (monthNumber === 0) month = 'Janeiro'
        else if (monthNumber === 1) month = 'Fevereiro'
        else if (monthNumber === 2) month = 'Março'
        else if (monthNumber === 3) month = 'Abril'
        else if (monthNumber === 4) month = 'Maio'
        else if (monthNumber === 5) month = 'Junho'
        else if (monthNumber === 6) month = 'Julho'
        else if (monthNumber === 7) month = 'Agosto'
        else if (monthNumber === 8) month = 'Setembro'
        else if (monthNumber === 9) month = 'Outubro'
        else if (monthNumber === 10) month = 'Novembro'
        else if (monthNumber === 11) month = 'Dezembro'

        if (hours.length === 1) hours = `0${hours}`
        if (minutes.length === 1) minutes = `0${minutes}`
        if (day.length === 1) day = `0${day}`

        const timeBlack = `${hours}:${minutes}, ${weekday} `
        const timeGray = `| ${day} de ${month} de ${year}`

        return { timeBlack, timeGray }
    }

    formatDate = date => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)

        return `${day}/${month}/${year}`
    }

    formatDateWithHours = date => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hours = date.substr(11, 5)

        return `${day}/${month}/${year} às ${hours}`
    }
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

export default connect(mapStateToProps)(AdminPanel)
