import React from 'react';
import classNames from 'classnames';
import Spinner from 'react-spinner-material';

import './style.sass';

/**
 * @param {number} props.size
 * @param {string} props.spinnerColor
 * @param {number} props.spinnerWidth
 * @param {boolean} props.visible
 */

const LoaderFull = props => {
    const { visible } = props;

    const containerClass = classNames('LoaderFull', {
        'is-active': visible,
    });

    return (
        <div className={containerClass}>
            <div className="LoaderFull-bg" />
            <div className="LoaderFull-box">
                <Spinner
                    size={props.size || 40}
                    spinnerColor={props.spinnerColor || '#FFB111'}
                    spinnerWidth={props.spinnerWidth || 3}
                    visible={true}
                />
            </div>
        </div>
    );
};

export default LoaderFull;
