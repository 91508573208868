import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import './style.scss'

export default class Pagination extends Component {
    render() {
        return (
            <div className="pagination-container">
                    <div className="numbers-container">
                        <ReactPaginate
                            breakLabel={'...'}
                            pageCount={this.props.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={this.props.onPageChange}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'number-item atual'}
                            previousLabel={
                                <div className="botao back">
                                    <FontAwesomeIcon icon="chevron-left" />
                                </div>
                            }
                            nextLabel={
                                <div className="botao next">
                                    <FontAwesomeIcon icon="chevron-right" />
                                </div>
                            }
                            />
                    </div>
            </div>
        )
    }
}
