import React from 'react'
import iconCheck from '../../assets/images/icon-check.png'

/**
 * @param {string} props.text Mensagem de confirmação
 */
const ModalSuccess = props => {

    const containerStyle = {
        padding: '3rem 0',
    }

    const iconContainerStyle = {
        marginBottom: '2rem',
        textAlign: 'center',
    }

    const textStyle = {
        fontSize: '1.6rem',
        textAlign: 'center',
    }

    return (
        <div style={containerStyle}>
            <div style={iconContainerStyle}>
                <img src={iconCheck}/>
            </div>
            <div style={textStyle}>{props.text}</div>
        </div>
    )
}

export default ModalSuccess
