import React, { Component } from "react";
import "./style.scss";

import TextInput from "../../components/Inputs/TextInput";
import SelectInput from "../../components/Inputs/SelectInput";
import PasswordInput from "../../components/Inputs/PasswordInput";
import BorderCard from "../../components/Cards/BorderCard";
import ShadowCard from "../../components/Cards/ShadowCard";
import OverlayCard from "../../components/Cards/OverlayCard";
import produto from "../../assets/images/product.png";
import IconInput from "../../components/Inputs/IconInput";

export default class MasterPage extends Component {
    render() {
        return (
            <div className="page-wrapper content-wrapper">
                <div className="container master-page">
                    <div className="hint">
                        Os componentes assumem 100% da largura de seu pai. Portanto, para larguras
                        customizadas, os componentes devem ser inseridos dentro de um pai com
                        largura customizada.
                    </div>
                    <div className="area-master-page">
                        <div className="titulo">Inputs</div>
                        <div className="conteudo-area">
                            <div className="area-item">
                                <TextInput
                                    colorLabel="#700000"
                                    labelInput="texto do label"
                                    msgErro="texto erro"
                                />
                            </div>
                            <div className="area-item">
                                <SelectInput
                                    colorLabel="#700000"
                                    labelInput="texto do label"
                                    msgErro="texto erro"
                                />
                            </div>
                            <div className="area-item">
                                <PasswordInput
                                    colorLabel="#700000"
                                    labelInput="senha"
                                    msgErro="senha inválida"
                                />
                            </div>
                            <div className="area-item">
                                <IconInput
                                    iconName="eye"
                                    labelInput="Meu Label"
                                    colorLabel="#700000"
                                    isIconRight={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="area-master-page">
                        <div className="titulo">Cards</div>
                        <div className="conteudo-area">
                            <div className="area-item">
                                <BorderCard corBorda="#700000">conteudo</BorderCard>
                            </div>
                            <div className="area-item">
                                <ShadowCard>teste</ShadowCard>
                            </div>
                            <div className="area-item">
                                <OverlayCard
                                    width={300}
                                    height={300}
                                    caminhoFoto={produto}
                                    corBorda="#700000"
                                >
                                    Um hambúrger de exemplo
                                </OverlayCard>
                                <div className="hint">
                                    É possivel passar uma largura e altura customizadas para o
                                    componente como props. Caso nao seja passada nenhum props de
                                    tamnho, assumirá 100% da largura e altura do pai
                                </div>
                            </div>
                            <div className="area-item" />
                        </div>
                    </div>

                    <div className="area-master-page">
                        <div className="titulo">Botões</div>
                        <div className="conteudo-area">
                            <div className="area-item" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
