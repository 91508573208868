import React, { Component } from 'react'
import Button from '../Button/Button'

export default class AddInternalComment extends Component {
    state = {
        mensagemErro: ''
    }

    render() {
        return (
            <div className="internal-comment-add">
                <div className="small-title">Comentário</div>
                <textarea rows={8} width="100%" value={this.props.textValue} onChange={this.props.onTextChange} />
                <div className="relatorio-erro">{this.state.mensagemErro}</div>
                <Button onClick={this.handleSubmit}>Enviar</Button>
            </div>
        )
    }

    handleSubmit = () => {
        if (this.props.textValue.length < 20) {
            this.setState({ mensagemErro: 'O comentário deve ter ao menos 20 caractéres' })
            return
        }

        this.setState({ mensagemErro: '' })

        this.props.onSendNote()
    }
}
