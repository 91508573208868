import React, { Component } from 'react'
import MessageBox from '../MessageBox'
import Modal from '../Modal'
import ModalConfirmation from '../ModalConfirmation'
import './style.scss'

export default class ModalEditText extends Component {
    constructor(props) {
        super(props)
        this.modal = React.createRef()
    }

    render() {
        return (
            <div ref={this.props.ref}>
                <Modal title={`Editar o Comentário Interno`} ref={this.modal}>
                    <ModalConfirmation
                        onYes={this.props.onYes}
                        onNo={this.props.onNo}
                        yesText="Salvar"
                        noText="Cancelar"
                    >
                        <div class="textarea-container">
                            <div className="owner">{this.props.editInfo.name}</div>
                            <div className="date-container">
                                <div className="date">{this.props.editInfo.date}</div>
                                <div className="updated">
                                    Atualizado em 10/05/2020
                                    {/* {this.props.editInfo.updated && `Atualizado em ${this.props.editInfo.updated}`} */}
                                </div>
                            </div>
                            <textarea rows="5" value={this.props.editText} onChange={this.props.onChange} />
                            <div className="erro">
                                <MessageBox mensagemErro={this.props.mensagemErro} />
                            </div>
                        </div>
                    </ModalConfirmation>
                </Modal>
            </div>
        )
    }

    openModal = () => this.modal.current.openModal()

    closeModal = () => this.modal.current.closeModal()

    formatDate = date => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hour = date.substr(10, 6)

        return `${day}/${month}/${year} às ${hour}`
    }
}
