import React, { Component } from 'react'
import './style.scss'

export default class InfoItemListagem extends Component {
  render() {
    return (
    <div className="item-listagem">
      <span class="titulo-listagem">{this.props.tituloListagem}</span>: <span class="conteudo-listagem">{this.props.conteudoListagem || '-'}</span>
    </div>
    )
  }
}
