import React, { Component } from 'react'
import FooterWrapper from '../PageWrapper/FooterWrapper';
import './style.scss'

export default class FooterBar extends Component {
    render() {
        return (
            
                <div className="footer-bar">
                    Copyright &copy; 2019 JCC. Todos os direitos reservados.
                </div>
           
        )
    }
}
