import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import SearchBar from '../../components/SearchBar'
import CabecalhoListagem from '../../components/CabecalhoListagem'
import ListagemItem from '../../components/ListagemItem'
import Pagination from '../../components/Pagination'

import LoaderFull from '../../components/LoaderFull'
import api, { baseURL } from '../../services/api'
import { Helmet } from 'react-helmet'

export default class ClientsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mensagemErro: '',
            clientSearch: '',
            clients: [],
            pageCount: 0
        }
    }

    render() {
        const { clients, clientSearch, mensagemErro } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>Clientes - JCC Ouvidoria</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="admin-clientes-protocolo-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="titulo">Clientes</div>
                                    <SearchBar
                                        textoBotao="Busca"
                                        widthInput="70%"
                                        placeholder="Buscar clientes..."
                                        onClickButton={this.handleSubmit}
                                        value={clientSearch}
                                        onChange={e => this.setState({ clientSearch: e.target.value })}
                                    />
                                    <div className="listagem-container">
                                        <CabecalhoListagem items={['Nome', 'E-mail', 'Último Login', 'Denúncias']} />
                                        {mensagemErro ? (
                                            <div className="msg-sem-ocorrencias">{mensagemErro}</div>
                                        ) : (
                                            clients.map((item, index) => (
                                                <ListagemItem
                                                    onClick={() => this.props.history.push(`/admin-cliente/${item.id}`)}
                                                    name={item.first_name}
                                                    email={item.email}
                                                    lastLogin={this.formatDate(item.last_login)}
                                                    occurrences={item.occurrences}
                                                    isClient={true}
                                                    key={index.toString()}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {this.state.pageCount > 1 && (
                                        <Pagination
                                            pageCount={this.state.pageCount}
                                            onPageChange={data => this.loadClients(data.selected + 1)}
                                        />
                                    )}
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = () => {
        this.handleSubmit()
    }

    handleSubmit = async () => {
        try {
            this.setState({ loading: true, mensagemErro: '', pageCount: 0 })

            const response = await api.get(`${baseURL}customers?search_value=${this.state.clientSearch}&order=desc`)

            const clients = response.data.data.items

            let pageCount = response.data.data.totals / 20
            if (Number.isInteger(pageCount)) pageCount = Number.parseInt(pageCount) + 1

            this.setState({
                loading: false,
                clients,
                pageCount,
                mensagemErro: clients.length === 0 ? 'Nenhum resultado encontrado.' : ''
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    loadClients = async page => {
        try {
            this.setState({ loading: true, mensagemErro: '' })

            let endpoint = `${baseURL}customers?search_field=${this.state.clientSearch}&current_page=${page}`
            if (!this.state.clientSearch) endpoint = `${baseURL}customers?current_page=${page}&order=desc`

            const response = await api.get(endpoint)

            const clients = response.data.data.items

            this.setState({
                loading: false,
                clients,
                mensagemErro: clients.length === 0 ? 'Nenhum resultado encontrado.' : ''
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    formatDate = date => {
        if (!date) return '-'

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hours = date.substr(11, 5)

        return `${day}/${month}/${year}`
    }
}
