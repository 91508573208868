import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'


export default class TextInput extends Component {
    render() {
        return (
            <div className="item-input">
                <label style={{ color: this.props.colorLabel }}>{this.props.labelInput}</label>
                <input
                    type={this.props.type || 'text'}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength}
                />
                {
                    this.props.msgErro &&
                    <div className="msg-erro"> <FontAwesomeIcon icon="info-circle" /> {this.props.msgErro}</div>
                }
            </div>
        )
    }
}
