import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from 'react-router-dom'
import MasterPage from '../MasterPage'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faFax,
    faAddressBook,
    faEye,
    faChevronDown,
    faCaretUp,
    faInfoCircle,
    faChevronRight,
    faChevronLeft,
    faPlusCircle,
    faCheckCircle,
    faSearch,
    faCalendar,
    faCheck,
    faTimes,
    faPencilAlt,
    faPaperclip,
    faCommentDots
} from '@fortawesome/free-solid-svg-icons'
import LoginPage from '../LoginPage'
import PasswordRecoveryOne from '../PasswordRecovery/StepOne'
import PasswordRecoveryTwo from '../PasswordRecovery/StepTwo'
import PasswordRecoveryThree from '../PasswordRecovery/StepThree'
import Page404 from '../Page404'
import Page401 from '../Page401'
import UserProfile from '../UserProfile'
import AdminPanel from '../AdminPanel'
import AdminProtocolSearch from '../AdminProtocolSearch'
import TicketPage from '../TicketPage'
import ClientsPage from '../ClientsPage'
import ClientInternaPage from '../ClientInternaPage'
import GestaoContainer from '../GestaoInformacoes'
import GestaoAdministradores from '../GestaoAdministradores'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AuthActions } from '../../store/actions'

library.add(
    faFax,
    faAddressBook,
    faEye,
    faChevronDown,
    faCaretUp,
    faInfoCircle,
    faChevronRight,
    faChevronLeft,
    faPlusCircle,
    faCheckCircle,
    faInfoCircle,
    faSearch,
    faCalendar,
    faTimes,
    faCheck,
    faPencilAlt,
    faPaperclip,
    faCommentDots
)

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    )
}

class App extends Component {
    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <PrivateRoute exact path="/" component={AdminPanel} isAuthenticated={this.isAuthenticated()} />
                        <PrivateRoute
                            exact
                            path="/perfil"
                            component={UserProfile}
                            isAuthenticated={this.isAuthenticated()}
                        />
                        <PrivateRoute
                            exact
                            path="/admin-ocorrencias"
                            component={AdminProtocolSearch}
                            isAuthenticated={this.isAuthenticated()}
                        />
                        <PrivateRoute
                            exact
                            path="/admin-ocorrencia/:id"
                            component={TicketPage}
                            isAuthenticated={this.isAuthenticated()}
                        />
                        <PrivateRoute
                            exact
                            path="/admin-clientes"
                            component={ClientsPage}
                            isAuthenticated={this.isAuthenticated()}
                        />
                        <PrivateRoute
                            exact
                            path="/admin-cliente/:id"
                            component={ClientInternaPage}
                            isAuthenticated={this.isAuthenticated()}
                        />

                        {this.isSuperAdmin() ? (
                            <PrivateRoute
                                exact
                                path="/administradores"
                                component={GestaoAdministradores}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        ) : (
                            <PrivateRoute
                                exact
                                path="/administradores"
                                component={Page401}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        )}
                        {this.isSuperAdmin() ? (
                            <PrivateRoute
                                exact
                                path="/assuntos"
                                component={props => (
                                    <GestaoContainer
                                        {...props}
                                        headerTitle="Assuntos"
                                        title="Assunto"
                                        endpoint="occurrence-topic"
                                        configFilter="topic"
                                    />
                                )}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        ) : (
                            <PrivateRoute
                                exact
                                path="/assuntos"
                                component={Page401}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        )}
                        {this.isSuperAdmin() ? (
                            <PrivateRoute
                                exact
                                path="/localidades"
                                component={props => (
                                    <GestaoContainer
                                        {...props}
                                        headerTitle="Localidades"
                                        title="Localidade"
                                        endpoint="mall"
                                        configFilter="mall"
                                    />
                                )}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        ) : (
                            <PrivateRoute
                                exact
                                path="/localidades"
                                component={Page401}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        )}
                        {this.isSuperAdmin() ? (
                            <PrivateRoute
                                exact
                                path="/setores"
                                component={props => (
                                    <GestaoContainer
                                        {...props}
                                        headerTitle="Setores"
                                        title="Setor"
                                        endpoint="sector"
                                        configFilter="sector"
                                    />
                                )}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        ) : (
                            <PrivateRoute
                                exact
                                path="/setores"
                                component={Page401}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        )}
                        {this.isSuperAdmin() ? (
                            <PrivateRoute
                                exact
                                path="/status"
                                component={props => (
                                    <GestaoContainer
                                        {...props}
                                        headerTitle="Status"
                                        title="Status"
                                        endpoint="status"
                                        plural="status"
                                        configFilter="status"
                                    />
                                )}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        ) : (
                            <PrivateRoute
                                exact
                                path="/status"
                                component={Page401}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        )}
                        {this.isSuperAdmin() ? (
                            <PrivateRoute
                                exact
                                path="/tipos"
                                component={props => (
                                    <GestaoContainer
                                        {...props}
                                        headerTitle="Tipos"
                                        title="Tipo"
                                        endpoint="occurrence-type"
                                        configFilter="type"
                                    />
                                )}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        ) : (
                            <PrivateRoute
                                exact
                                path="/tipos"
                                component={Page401}
                                isAuthenticated={this.isAuthenticated()}
                            />
                        )}

                        <Route exact path="/login" component={LoginPage} />
                        <Route exact path="/recuperar-senha" component={PasswordRecoveryOne} />
                        <Route exact path="/nova-senha/:token/:email" component={PasswordRecoveryTwo} />
                        <Route exact path="/senha-redefinida" component={PasswordRecoveryThree} />

                        <Route exact path="/master-page" component={MasterPage} />
                        <Route component={Page404} />

                        {/* <Route exact path="/" component={IntroPage} /> */}
                        {/* <Route exact path="/minhas-ocorrencias" component={UserOcurrences} /> */}
                        {/* <Route exact path="/consulta" component={ProtocolSearch} /> */}
                        {/* <Route exact path="/reclamacao" component={FormOuvidoriaPage} /> */}
                        {/* <Route exact path="/ocorrencia-enviada" component={OcurrenceSent} /> */}
                    </Switch>
                </Router>
            </>
        )
    }

    componentDidMount = async () => {
        const { user } = this.props
        const userStorage = localStorage.getItem('@IguatemiOuvidoriaAdmin:admin')

        if (!user && userStorage) {
            const id = JSON.parse(userStorage).id
            this.props.loginMe(id)
        }
    }

    isAuthenticated = () => {
        return this.props.user || localStorage.getItem('@IguatemiOuvidoriaAdmin:admin')
    }

    isSuperAdmin = () => {
        if (!this.props.user) return false

        const role = this.props.user.roles[0].role_id

        if (role === 1) return true

        return false
    }
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = dispatch => bindActionCreators({ ...AuthActions }, dispatch)

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
)
