import React, { useEffect, useRef } from 'react'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import TextInputMask from '../Inputs/TextInputMask'

const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
]

const WEEKDAYS_LONG = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

const DatePicker = props => {
    const inputWrapperRef = useRef()

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
    }, [])

    const handleOutsideClick = event => {
        if (inputWrapperRef.current && !inputWrapperRef.current.contains(event.target)) {
            props.toggleDatePicker(props.period, true)
        }
    }

    const modifiers = {
        highlighted: props.selectedDate
    }

    const modifiersStyles = {
        highlighted: {
            color: 'white',
            backgroundColor: '#ffb111'
        }
    }

    return (
        <div className="input-item calendar" ref={inputWrapperRef}>
            <div style={{ position: 'relative' }}>
                <TextInputMask
                    isIconRight={true}
                    iconName="calendar"
                    mask="99/99/9999"
                    labelInput={props.periodLabel}
                    value={props.inputValue}
                    onChange={e => props.onChange(props.periodStateLabel, e.target.value)}
                    onIconClick={() => props.toggleDatePicker(props.period)}
                    msgErro={props.errorMsg}
                />
                {props.show && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: -220,
                            right: 0,
                            backgroundColor: '#FFF',
                            borderWidth: 2,
                            borderColor: '#ffb111',
                            zIndex: 10
                        }}
                    >
                        <DayPicker
                            locale={'br'}
                            months={MONTHS}
                            weekdaysLong={WEEKDAYS_LONG}
                            weekdaysShort={WEEKDAYS_SHORT}
                            firstDayOfWeek={0}
                            onDayClick={day => props.handleDayChange(props.period, day)}
                            selectedDays={props.selectedDate}
                            modifiers={modifiers}
                            modifiersStyles={modifiersStyles}
                            showOutsideDays
                            fixedWeeks
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default DatePicker
