import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from './style.module.scss'

import { AuthActions } from '../../../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class DesktopMenuItemDropdown extends Component {

    render() {
        return (
            <div className={style.menuItem} onClick={this.props.setModalOpen}>
                <div className={style.nomeItem}>{this.props.tituloDropdown}</div>
                <div className="icone-dropdown">
                    <FontAwesomeIcon icon="chevron-down" />
                </div>
                <div
                    className={style.modalUsuario}
                    style={{ display: this.props.showModal ? 'block' : 'none' }}
                >
                    <div className={style.iconeCaret}>
                        <FontAwesomeIcon icon="caret-up" />
                    </div>
                    {
                        this.props.isAdmin
                        ?
                        <div className={style.listagemDropdown}>
                            <div className={style.dropdownItem}>
                                <Link to="/administradores">Administradores</Link>
                            </div>
                            <div className={style.dropdownItem}>
                                <Link to='/assuntos' >Assuntos</Link>
                            </div>
                            <div className={style.dropdownItem}>
                                <Link to="/localidades">Localidades</Link>
                            </div>
                            <div className={style.dropdownItem}>
                                <Link to="/setores">Setores</Link>
                            </div>
                            <div className={style.dropdownItem}>
                                <Link to="/status">Status</Link>
                            </div>
                            <div className={style.dropdownItem}>
                                <Link to="/tipos">Tipos</Link>
                            </div>
                        </div>
                        :
                        <div className={style.listagemDropdown}>
                            <div className={style.dropdownItem}>
                                <Link to="/perfil">Meus dados</Link>
                            </div>
                            <div className={style.dropdownItem} onClick={this.props.logout}>
                                Sair
                            </div>
                        </div>

                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = dispatch => bindActionCreators({ ...AuthActions }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopMenuItemDropdown)