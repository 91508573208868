import React, { Component } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import ResponsiveHeader from '../../../components/ResponsiveHeader'
import ContentWrapper from '../../../components/PageWrapper/ContentWrapper'
import FooterBar from '../../../components/FooterBar'
import BorderCard from '../../../components/Cards/BorderCard'
import TextInput from '../../../components/Inputs/TextInput'
import Button from '../../../components/Button/Button'
import arvore from '../../../assets/images/arvore-card.png'

import Modal from '../../../components/Modal'
import ModalSuccess from '../../../components/ModalSuccess'
import LoaderFull from '../../../components/LoaderFull'
import MessageBox from '../../../components/MessageBox'
import { baseURL } from '../../../services/api'
import * as axios from 'axios'
import { Helmet } from 'react-helmet'

export default class PasswordRecoveryOne extends Component {
    constructor(props) {
        super(props)

        this.modalSuccessRef = React.createRef()

        this.state = {
            showEdit: false,
            loading: false,
            email: '',
            mensagemErro: '',
            bgIndex: 1
        }
    }

    render() {
        return (
            <PageWrapper>
                <Helmet>
                    <title>Recuperar Senha - JCC Ouvidoria</title>
                </Helmet>
                <Modal title="Alteração de perfil" size="small" ref={this.modalSuccessRef}>
                    <ModalSuccess text="Email de recuperação enviado com sucesso" />
                </Modal>
                <LoaderFull visible={this.state.loading} />
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="bg-container-login">
                        <div className={`bg-item ${this.state.bgIndex === 1 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-1.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 2 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-4.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 3 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-5.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 4 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-6.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 5 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-7.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 6 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-8.jpg')} />
                        </div>
                    </div>
                    <div className={`container-login-page`}>
                        <div className="container-card-recovery">
                            <BorderCard corBorda="transparent">
                                <div className="conteudo-card-login">
                                    <div className="img-arvore">
                                        <img src={arvore} alt="" />
                                    </div>
                                    <div className="big-titulo">Esqueci minha senha</div>
                                    <div className="subtitulo">
                                        Informe seu e-mail para iniciar o processo de recuperação de senha.
                                    </div>
                                    <div className="input-item">
                                        <TextInput
                                            msgErro={this.state.emailError}
                                            labelInput="Email:"
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })}
                                        />
                                    </div>

                                    <MessageBox mensagemErro={this.state.mensagemErro} />

                                    <div className="buttons-container">
                                        <div className="button-item">
                                            <Button color="primary" onClick={this.handleSubmit}>
                                                enviar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </BorderCard>
                        </div>
                    </div>
                </ContentWrapper>
                <div style={{ position: 'relative', zIndex: 100 }}>
                    <FooterBar />
                </div>
            </PageWrapper>
        )
    }

    componentDidMount = () => {
        this.bgInterval = setInterval(
            () =>
                this.setState({
                    bgIndex: this.state.bgIndex < 6 ? this.state.bgIndex + 1 : 1
                }),
            5000
        )
    }

    componentWillUnmount = () => {
        clearInterval(this.bgInterval)
    }

    handleSubmit = async e => {
        e.preventDefault()

        const emailError = this.isEmailValid() ? '' : 'Email inválido'

        this.setState({ emailError, mensagemErro: '' })

        if (emailError) return

        this.setState({ loading: true })

        try {
            await axios.post(`${baseURL}recover`, { email: this.state.email })
            this.setState({ loading: false })
            this.openModal(this.modalSuccessRef)
        } catch (error) {
            const err = { error }

            if (err.error && err.error.response.status === 404) {
                this.setState({ loading: false, mensagemErro: 'Email não encontrado ou inválido.' })
            } else {
                this.setState({
                    loading: false,
                    mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
                })
            }
        }
    }

    isEmailValid = () => {
        const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+$/
        return regex.test(this.state.email)
    }

    openModal = modalRef => {
        modalRef.current.openModal()
    }
}
