import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './style.scss'

export default class UnderlinedLink extends Component {
  render() {
    return (
      <Link to={this.props.link} className="underlined-link">{this.props.textoLink}</Link>
    )
  }
}
