import React, { Component, Fragment } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import SearchBar from '../../components/SearchBar'
import CabecalhoListagem from '../../components/CabecalhoListagem'
import ListagemItem from '../../components/ListagemItem'
import Pagination from '../../components/Pagination'
import TextInput from '../../components/Inputs/TextInput'
import SelectInput from '../../components/Inputs/SelectInput'
import TextInputMask from '../../components/Inputs/TextInputMask'
import Button from '../../components/Button/Button'

import LoaderFull from '../../components/LoaderFull'
import api, { baseURL } from '../../services/api'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import DatePicker from '../../components/DatePicker'
import Modal from '../../components/Modal'

const filters = [
    { id: 'protocol_number', name: 'Número de Protocolo' },
    { id: 'mall_id', name: 'Local' },
    { id: 'sector_id', name: 'Setor' },
    { id: 'status_id', name: 'Status' },
    { id: 'type_id', name: 'Tipo' },
    { id: 'topic_id', name: 'Tópico' }
]

const filterClients = [
    { id: 'all', name: 'Todos' },
    { id: 'anonymous', name: 'Anônimos' },
    { id: 'search', name: 'Buscar cliente' }
]

const situations = [
    { id: 'all', name: 'Todas' },
    { id: '0', name: 'Novas' },
    { id: '1', name: 'Ativas' },
    { id: '2', name: 'Respondidas' },
    { id: '3', name: 'Concluídas' }
]

export default class AdminProtocolSearch extends Component {
    constructor(props) {
        super(props)

        let situation = 'all'
        if (props.location.state && props.location.state.situation) situation = props.location.state.situation

        this.state = {
            showSearch: false,
            occurrences: [],
            totals: '',
            loading: false,
            mensagemErro: '',

            generalSearch: '',

            filterBy: '',
            filterByValue: '',
            filterByOptions: {},
            filterByArray: [],

            searchFields: {
                client: '',
                startDate: '',
                endDate: ''
            },
            searchErrors: {
                client: '',
                startDate: '',
                endDate: ''
            },

            filterClientsBy: 'todos',
            disableClientInput: true,
            isAnonymous: false,
            pageCount: 0,
            situation,
            lastSearchEndpoint: '',

            showDatePickerStart: false,
            showDatePickerEnd: false,
            selectedDateStart: new Date(),
            selectedDateEnd: new Date(),

            modalTitle: '',
            modalMessage: ''
        }

        this.modalMessageRef = React.createRef()
    }

    render() {
        const {
            occurrences,
            mensagemErro,
            searchFields,
            searchErrors,
            generalSearch,
            filterBy,
            filterByValue,
            filterByArray,
            disableClientInput,
            showDatePickerStart,
            showDatePickerEnd,
            selectedDateStart,
            selectedDateEnd,
            totals,
            modalMessage,
            modalTitle
        } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>Ocorrências - JCC Ouvidoria</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <ResponsiveHeader />
                <Modal title={modalTitle} ref={this.modalMessageRef}>
                    <div className="modal-message">{modalMessage}</div>
                </Modal>
                <ContentWrapper>
                    <div className="admin-consulta-protocolo-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="titulo">Ocorrências</div>
                                    <SearchBar
                                        textoBotao="Busca Avançada"
                                        widthInput="70%"
                                        placeholder='Ex: "estacionamento"'
                                        onClickButton={this.toggleSearch}
                                        onClickIcon={this.regularSearch}
                                        value={generalSearch}
                                        onChange={e => this.setState({ generalSearch: e.target.value })}
                                    />
                                    <div style={{ display: this.state.showSearch ? 'block' : 'none' }}>
                                        <div className="container-busca-avancada">
                                            <div className="input-line">
                                                <div className="input-item">
                                                    <SelectInput
                                                        labelInput="Filtrar por"
                                                        defaultValue={filterBy}
                                                        value={filterBy}
                                                        options={filters}
                                                        onChange={e => this.setFilter(e.target.value)}
                                                    />
                                                </div>
                                                {!filterBy ? (
                                                    <></>
                                                ) : filterBy === 'protocol_number' ? (
                                                    <div className="input-item">
                                                        <TextInput
                                                            labelInput="Nº de Protocolo"
                                                            value={filterByValue}
                                                            onChange={e =>
                                                                this.setState({
                                                                    filterByValue: e.target.value.toUpperCase()
                                                                })
                                                            }
                                                            msgErro={searchErrors.protocol}
                                                            maxLength={18}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="input-item">
                                                        <SelectInput
                                                            labelInput="Status"
                                                            value={filterByValue}
                                                            defaultValue={filterByValue}
                                                            options={filterByArray}
                                                            onChange={e =>
                                                                this.setState({ filterByValue: e.target.value })
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                <div className="input-item">
                                                    <div className="input-line">
                                                        <DatePicker
                                                            inputValue={searchFields.startDate}
                                                            period={'start'}
                                                            periodLabel={'Período - Início'}
                                                            periodStateLabel={'startDate'}
                                                            onChange={this.setSearch}
                                                            toggleDatePicker={this.toggleDatePicker}
                                                            show={showDatePickerStart}
                                                            selectedDate={selectedDateStart}
                                                            handleDayChange={this.handleDayChange}
                                                            errorMsg={searchErrors.startDate}
                                                        />
                                                        <DatePicker
                                                            inputValue={searchFields.endDate}
                                                            period={'end'}
                                                            periodLabel={'Período - Fim'}
                                                            periodStateLabel={'endDate'}
                                                            toggleDatePicker={this.toggleDatePicker}
                                                            onChange={this.setSearch}
                                                            show={showDatePickerEnd}
                                                            selectedDate={selectedDateEnd}
                                                            handleDayChange={this.handleDayChange}
                                                            errorMsg={searchErrors.endDate}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-line">
                                                <div className="input-item">
                                                    <SelectInput
                                                        labelInput="Situação"
                                                        defaultValue={this.state.situation}
                                                        value={this.state.situation}
                                                        options={situations}
                                                        msgErro={searchErrors.situation}
                                                        onChange={e => this.setState({ situation: e.target.value })}
                                                    />
                                                </div>
                                                <div className="input-item">
                                                    <SelectInput
                                                        labelInput="Filtrar Clientes"
                                                        defaultValue={this.state.filterClientsBy}
                                                        value={this.state.filterClientsBy}
                                                        options={filterClients}
                                                        msgErro={searchErrors.status}
                                                        onChange={e => this.setFilterClients(e.target.value)}
                                                    />
                                                </div>
                                                {!disableClientInput && (
                                                    <div className="input-item">
                                                        <TextInput
                                                            labelInput="Cliente"
                                                            value={searchFields.client}
                                                            onChange={e => this.setSearch('client', e.target.value)}
                                                            msgErro={searchErrors.client}
                                                            placeholder="Nome ou email"
                                                        />
                                                    </div>
                                                )}
                                                {disableClientInput && <div className="input-item" />}
                                            </div>
                                            <div className="container-botoes-submit">
                                                <div className="button-item">
                                                    <Button color="primary" onClick={this.advancedSearch}>
                                                        Buscar
                                                    </Button>
                                                </div>
                                                <div className="button-item">
                                                    <Button color="primary" outline onClick={this.clearFields}>
                                                        Limpar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="listagem-container">
                                        <div className="export-totals-container">
                                            {totals > 0 && (
                                                <div className="msg-total-ocorrencias">
                                                    {totals === 1
                                                        ? 'Foi encontrada 1 ocorrência.'
                                                        : `Foram encontradas ${totals} ocorrências.`}
                                                </div>
                                            )}

                                            {!mensagemErro && occurrences.length > 0 && (
                                                <div className="button-item export-button">
                                                    <Button
                                                        color="green"
                                                        outline
                                                        onClick={() => this.exportOccurrences()}
                                                    >
                                                        Exportar Ocorrências
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                        <CabecalhoListagem
                                            items={[
                                                'Protocolo',
                                                'Usuário',
                                                'Local',
                                                'Data de Criação',
                                                'Última atualização',
                                                'Situação',
                                                'Status'
                                            ]}
                                        />
                                        {mensagemErro ? (
                                            <div className="msg-sem-ocorrencias">{mensagemErro}</div>
                                        ) : (
                                            occurrences.map((item, index) => (
                                                <ListagemItem
                                                    key={index}
                                                    onClick={() =>
                                                        this.props.history.push(`/admin-ocorrencia/${item.id}`)
                                                    }
                                                    valorProtocolo={item.protocol_number}
                                                    valorLocal={item.mall.name}
                                                    valorCriacao={this.formatDate(item.created_at)}
                                                    valorAtt={this.formatDate(item.updated_at)}
                                                    valorStatus={item.status && item.status.name}
                                                    valorUsuario={item.customer ? item.customer.first_name : ''}
                                                    valorSituacao={item.substatus}
                                                    hasReports={item.reports.length > 0}
                                                    hasNotes={item.notes.length > 0}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {this.state.pageCount > 1 && (
                                        <Pagination
                                            pageCount={this.state.pageCount}
                                            onPageChange={data => this.loadOccurrences(data.selected + 1)}
                                        />
                                    )}
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = async () => {
        try {
            this.setFilter('protocol_number')
            this.setState({ loading: true, mensagemErro: '' })

            let occurrencesEndpoint = `${baseURL}occurrences?order=desc`

            if (this.state.situation !== 'all') occurrencesEndpoint += `&substatus=${this.state.situation}`

            const requests = await axios.all([
                api.get(occurrencesEndpoint),
                api.get(`${baseURL}malls?per_page=-1`),
                api.get(`${baseURL}sectors?per_page=-1`),
                api.get(`${baseURL}status?per_page=-1`),
                api.get(`${baseURL}occurrence-topics?per_page=-1`),
                api.get(`${baseURL}occurrence-types?per_page=-1`)
            ])

            const occurrences = requests[0].data.data.items
            const totals = requests[0].data.data.totals
            const malls = requests[1].data.data.items
            const sectors = requests[2].data.data.items
            const status = [{ id: -1, name: 'Todos' }, ...requests[3].data.data.items]
            const topics = requests[4].data.data.items
            const types = requests[5].data.data.items

            const filterByOptions = { malls, sectors, status, topics, types }

            const lastSearchEndpoint = occurrencesEndpoint
            let pageCount = totals / 20
            if (Number.isInteger(pageCount)) pageCount = Number.parseInt(pageCount) + 1

            this.setState({
                loading: false,
                occurrences,
                totals,
                lastSearchEndpoint,
                pageCount,
                filterByOptions,
                mensagemErro: occurrences.length ? '' : 'Não existem ocorrências cadastradas.',
                showSearch: this.state.situation !== 'all'
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    formatDate = date => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)

        return `${day}/${month}/${year}`
    }

    setFilter = filterBy => {
        this.setState({ filterBy, filterByValue: '' })
        this.setSearch('protocol', '')

        if (filterBy === 'protocol_number') return

        const { filterByOptions } = this.state

        let filterByArray = []

        if (filterBy === 'mall_id') filterByArray = filterByOptions.malls
        else if (filterBy === 'sector_id') filterByArray = filterByOptions.sectors
        else if (filterBy === 'status_id') filterByArray = filterByOptions.status
        else if (filterBy === 'topic_id') filterByArray = filterByOptions.topics
        else if (filterBy === 'type_id') filterByArray = filterByOptions.types
        else if (filterBy === 'user_id') filterByArray = filterByOptions.customers

        this.setState({ filterByArray })
    }

    setFilterClients = filterType => {
        filterType = filterType.toString()

        this.setSearch('client', '')
        this.setState({ filterClientsBy: filterType })

        if (filterType === 'all') this.setState({ isAnonymous: false, disableClientInput: true })
        else if (filterType === 'anonymous') this.setState({ isAnonymous: true, disableClientInput: true })
        else if (filterType === 'search') this.setState({ isAnonymous: false, disableClientInput: false })
    }

    setSearch = (key, value) => {
        let { searchFields } = this.state
        searchFields[key] = value
        this.setState({ searchFields })
    }

    clearFields = () => {
        this.setState({
            filterBy: 'protocol_number',
            filterByValue: '',

            searchFields: {
                client: '',
                startDate: '',
                endDate: ''
            },
            searchErrors: {
                client: '',
                startDate: '',
                endDate: ''
            },

            filterClientsBy: 'all',
            situation: 'all',
            disableClientInput: true,

            showDatePickerStart: false,
            showDatePickerEnd: false
        })
    }

    toggleDatePicker = (period, disable) => {
        const { showDatePickerStart, showDatePickerEnd } = this.state

        if (period === 'start') {
            if (disable) this.setState({ showDatePickerStart: false })
            else
                this.setState({
                    showDatePickerStart: !showDatePickerStart,
                    showDatePickerEnd: false
                })
            return
        }

        if (period === 'end') {
            if (disable) this.setState({ showDatePickerEnd: false })
            else
                this.setState({
                    showDatePickerEnd: !showDatePickerEnd,
                    showDatePickerStart: false
                })
            return
        }
    }

    handleDayChange = (period, date) => {
        const { searchFields } = this.state

        let year = date.getFullYear(0, 4).toString()
        let month = (date.getMonth() + 1).toString()
        let day = date.getDate().toString()

        if (month.length === 1) month = '0' + month

        if (day.length === 1) day = '0' + day

        const formatted = `${day}/${month}/${year}`

        if (period === 'start') {
            this.setState({
                selectedDateStart: new Date(date),
                searchFields: {
                    ...searchFields,
                    startDate: formatted
                },
                showDatePickerStart: false
            })
            return
        } else if (period === 'end') {
            this.setState({
                selectedDateEnd: new Date(date),
                searchFields: {
                    ...searchFields,
                    endDate: formatted
                },
                showDatePickerEnd: false
            })
            return
        }
    }

    toggleSearch = () => {
        this.setState({
            showSearch: !this.state.showSearch
        })
    }

    regularSearch = async () => {
        try {
            this.setState({ loading: true, mensagemErro: '', pageCount: 0 })

            let endpoint = `${baseURL}occurrences?order=desc&search_by=narrative&search_value=${this.state.generalSearch}`

            if (!this.state.generalSearch) endpoint = `${baseURL}occurrences?order=desc`

            const response = await api.get(endpoint)

            const occurrences = response.data.data.items
            const totals = response.data.data.totals

            const lastSearchEndpoint = endpoint
            let pageCount = totals / 20
            if (Number.isInteger(pageCount)) pageCount = Number.parseInt(pageCount) + 1

            this.setState({
                loading: false,
                pageCount,
                totals,
                lastSearchEndpoint,
                occurrences,
                mensagemErro: occurrences.length === 0 ? 'Nenhum resultado encontrado.' : ''
            })
        } catch (error) {
            this.setState({
                loading: false,
                mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
            })
        }
    }

    advancedSearch = async () => {
        if (!this.validateFields()) return

        const { filterBy, filterByValue, searchFields, situation, isAnonymous } = this.state
        const { startDate, endDate, client } = searchFields

        let endpoint = `${baseURL}occurrences?order=desc`

        if (filterByValue && filterByValue !== '-1') endpoint += `&filter_by=${filterBy}&filter_value=${filterByValue}`

        if (startDate) {
            const date = `${startDate.substr(6, 4)}-${startDate.substr(3, 2)}-${startDate.substr(0, 2)}`
            endpoint += `&start_date=${date}`
        }
        if (endDate) {
            const date = `${endDate.substr(6, 4)}-${endDate.substr(3, 2)}-${endDate.substr(0, 2)}`
            endpoint += `&end_date=${date}`
        }

        if (situation !== 'all') endpoint += `&substatus=${situation}`

        if (isAnonymous) endpoint += '&anonymous=1'
        else if (client) endpoint += `&customer=${client}`

        if (endpoint.substr(endpoint.length - 1) === '?') endpoint = endpoint.substring(0, endpoint.length - 1)

        try {
            this.setState({
                loading: true,
                mensagemErro: '',
                pageCount: 0,
                showDatePickerStart: false,
                showDatePickerEnd: false
            })

            const response = await api.get(endpoint)

            const occurrences = response.data.data.items
            const totals = response.data.data.totals

            const lastSearchEndpoint = endpoint
            let pageCount = totals / 20
            if (Number.isInteger(pageCount)) pageCount = Number.parseInt(pageCount) + 1

            this.setState({
                loading: false,
                occurrences,
                totals,
                pageCount,
                lastSearchEndpoint,
                mensagemErro: occurrences.length === 0 ? 'Nenhum resultado encontrado.' : ''
            })
        } catch (error) {
            let mensagemErro = ''

            if (error.response.status === 422) {
                const errors = error.response.data.errors

                if (errors.start_date) mensagemErro += `Data inicial inválida.\n`
                if (errors.end_date) mensagemErro += `Data final inválida.\n`
            } else mensagemErro = 'Ocorreu um erro inesperado. Tente novamente mais tarde.'

            this.setState({ loading: false, mensagemErro })
        }
    }

    validateFields = () => {
        const { startDate, endDate } = this.state.searchFields

        const { filterBy, filterByValue } = this.state

        let errors = {}

        const startString = startDate.split('/')
        const start = new Date(startString[2], startString[1] - 1, startString[0])
        const endString = endDate.split('/')
        const end = new Date(endString[2], endString[1] - 1, endString[0])

        if (startDate.length > 0 && startDate.indexOf('_') !== -1) errors.startDate = 'Data inválida'
        if (endDate.length > 0 && endDate.length < 10) errors.endDate = 'Data inválida'
        if (startDate && endDate && end < start) {
            errors.startDate = 'Data de início não pode ser maior que data de fim'
            errors.endDate = 'Data de início não pode ser maior que data de fim'
        }
        if (filterBy === 'protocol_number' && filterByValue.length > 0) {
            if (filterByValue.length < 18) errors.protocol = 'Protocolo inválido'
            else if (Number(filterByValue.substr(0, 14)).toString() === 'NaN') errors.protocol = 'Protocolo inválido'
        }

        this.setState({ searchErrors: errors })

        return JSON.stringify(errors) === '{}'
    }

    loadOccurrences = async page => {
        try {
            this.setState({ loading: true, mensagemErro: '' })

            let endpoint = this.state.lastSearchEndpoint

            if (endpoint.indexOf('?') !== -1) endpoint += `&current_page=${page}`
            else endpoint += `?current_page=${page}`

            const responseOccurrences = await api.get(endpoint)
            const occurrences = responseOccurrences.data.data.items
            const totals = responseOccurrences.data.data.totals

            this.setState({
                loading: false,
                occurrences,
                totals,
                mensagemErro: occurrences.length ? '' : 'Não existem ocorrências cadastradas.'
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    exportOccurrences = async () => {
        try {
            this.setState({ loading: true, mensagemErro: '' })

            let endpoint = this.state.lastSearchEndpoint

            if (endpoint.indexOf('?') !== -1) endpoint += `&per_page=-1`
            else endpoint += `?per_page=-1`

            endpoint += '&export=true'

            const responseExport = await api.get(endpoint, { responseType: 'blob' })

            const file = new Blob([responseExport.data], { type: responseExport.data.type })

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file)
                return
            }

            const data = window.URL.createObjectURL(file)
            let link = document.createElement('a')
            link.href = data
            link.download = `ouvidoria-relatorio-busca`
            link.click()
            setTimeout(() => window.URL.revokeObjectURL(data), 100)

            this.setState({ loading: false })
        } catch (error) {
            this.setState(
                {
                    loading: false,
                    modalTitle: 'Erro',
                    modalMessage: 'Ocorreu um erro na exportação do relatório. Tente novamente mais tarde.'
                },
                () => this.openModal(this.modalMessageRef)
            )
        }
    }

    openModal = modalRef => modalRef.current.openModal()
    closeModal = modalRef => modalRef.current.closeModal()
}
