import React from 'react'

import classNames from 'classnames'

const Button = props => {
    const buttonClass = classNames('button', props.disabled ? 'secondary' : props.color ? props.color : 'primary', {
        outline: props.outline
    })

    return (
        <button className={buttonClass} onClick={props.onClick} disabled={props.disabled}>
            {props.children}
        </button>
    )
}

export default Button
