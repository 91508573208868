import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader';
import FooterBar from '../../components/FooterBar';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import ContentWrapper from '../../components/PageWrapper/ContentWrapper';
import arvore from '../../assets/images/arvore.png';
import BorderCard from '../../components/Cards/BorderCard';
import Button from '../../components/Button/Button';
import { Helmet } from 'react-helmet'


export default class Page401 extends Component {
    render() {
        return (
            <PageWrapper>
                <Helmet>
                    <title>Página não autorizada - JCC Ouvidoria</title>
                </Helmet>
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="page-404-container">
                        <div className="img-arvore">
                            <img src={arvore} alt="" />
                        </div>
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="conteudo-card-404">
                                        <div className="number">401</div>
                                        <div className="titulo">Página não autorizada.</div>
                                        <div className="subtitulo">Você não tem autorização para acessar essa página.</div>
                                        <Button color="primary" onClick={() => this.props.history.push('/')}>Ir para a página inicial</Button>
                                    </div>
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }
}
