import axios from 'axios'

import { store } from '../store'
import { AuthActions } from '../store/actions'

// let urlBase = 'https://api.ouvidoria.idx.digital/v1/'

// if (process.env.NODE_ENV === 'production') urlBase = 'https://srv.aplicativorepasse.com.br'

export const baseURL = window.location.hostname.includes('ouvidoria.jccbr.com')
    ? 'https://api.ouvidoria.jccbr.com/v1/'
    : 'https://api.ouvidoria.idx.digital/v1/'
const api = axios.create({
    baseURL
})

api.interceptors.request.use(async request => {
    const token = await localStorage.getItem('@IguatemiOuvidoriaAdmin:token')

    if (token) request.headers.Authorization = `Bearer ${token}`

    return request
})

api.interceptors.response.use(
    response => response,
    async error => {
        const {
            config,
            response: { data }
        } = error

        if (data.message === 'Token has expired') {
            console.log('Token expired error', { error })
            try {
                const response = await api.post('refresh')
                const { token } = response.data.data

                localStorage.setItem('@IguatemiOuvidoriaAdmin:token', token)
                config.headers.Authorization = `Bearer ${token}`

                return Promise.resolve()
            } catch (err) {
                store.dispatch(AuthActions.logout())
                console.log(err)
            }
        } else if (data.error === 'token_not_provided' || data.error === 'token_invalid') {
            store.dispatch(AuthActions.logoutUser())
            return Promise.resolve()
        } else {
            return Promise.reject(error)
        }
    }
)

export default api
