import React, { Component } from "react";
import "./style.scss";

export default class ShadowCard extends Component {
    render() {
        return (
            <div className="shadow-card" style={{ padding: this.props.padding }}>
                {this.props.children}
            </div>
        );
    }
}
