import React, { Component } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import ResponsiveHeader from '../../../components/ResponsiveHeader'
import ContentWrapper from '../../../components/PageWrapper/ContentWrapper'
import FooterBar from '../../../components/FooterBar'
import BorderCard from '../../../components/Cards/BorderCard'
import Button from '../../../components/Button/Button'
import arvore from '../../../assets/images/arvore-card.png'
import PasswordInput from '../../../components/Inputs/PasswordInput'

import LoaderFull from '../../../components/LoaderFull'
import MessageBox from '../../../components/MessageBox'
import { baseURL } from '../../../services/api'
import * as axios from 'axios'
import { Helmet } from 'react-helmet'

export default class PasswordRecoveryTwo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            password_confirmation: '',
            loading: false,
            mensagemErro: '',
            errors: {},
            bgIndex: 1
        }
    }

    render() {
        const { errors, password, password_confirmation } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>Nova Senha - JCC Ouvidoria</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="bg-container-login">
                        <div className={`bg-item ${this.state.bgIndex === 1 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-1.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 2 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-4.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 3 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-5.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 4 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-6.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 5 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-7.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 6 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-8.jpg')} />
                        </div>
                    </div>
                    <div className={`container-login-page`}>
                        <div className="container-card-recovery">
                            <BorderCard corBorda="transparent">
                                <div className="conteudo-card-login">
                                    <div className="img-arvore">
                                        <img src={arvore} alt="" />
                                    </div>
                                    <div className="big-titulo">Esqueci minha senha</div>
                                    <div className="subtitulo">Informe sua nova senha.</div>
                                    <div className="input-item">
                                        <PasswordInput
                                            labelInput="Nova Senha"
                                            msgErro={errors.password}
                                            value={password}
                                            onChange={e => this.setState({ password: e.target.value })}
                                        />
                                    </div>
                                    <div className="input-item">
                                        <PasswordInput
                                            labelInput="Confirmar Nova Senha"
                                            msgErro={errors.password_confirmation}
                                            value={password_confirmation}
                                            onChange={e => this.setState({ password_confirmation: e.target.value })}
                                        />
                                    </div>

                                    <MessageBox mensagemErro={this.state.mensagemErro} />

                                    <div className="buttons-container">
                                        <div className="button-item">
                                            <Button onClick={this.handleSubmit} color="primary">
                                                enviar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </BorderCard>
                        </div>
                    </div>
                </ContentWrapper>
                <div style={{ position: 'relative', zIndex: 100 }}>
                    <FooterBar />
                </div>
            </PageWrapper>
        )
    }

    componentDidMount = () => {
        this.bgInterval = setInterval(
            () =>
                this.setState({
                    bgIndex: this.state.bgIndex < 6 ? this.state.bgIndex + 1 : 1
                }),
            5000
        )
    }

    componentWillUnmount = () => {
        clearInterval(this.bgInterval)
    }

    openModal = modalRef => {
        return modalRef.current.openModal()
    }

    handleSubmit = async () => {
        const { password, password_confirmation } = this.state

        let errors = {}

        if (password.length < 8) errors.password = 'A senha deve ter ao menos 8 caractéres'
        if (password !== password_confirmation) errors.password_confirmation = 'A senhas não correspondem'

        this.setState({ errors })

        if (JSON.stringify(errors) !== '{}') return

        this.setState({ loading: true })

        try {
            await axios.post(`${baseURL}reset`, {
                email: this.props.match.params.email,
                token: this.props.match.params.token,
                password,
                password_confirmation
            })

            this.setState({ loading: false })
            this.props.history.push('/senha-redefinida')
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }
}
