import React from "react";

import classNames from "classnames/bind";
import classes from "./InfoItem.module.scss";
import PropTypes from "prop-types";

const InfoItem = props => {
    const cx = classNames.bind(classes);

    const className = cx({
        item: true,
        isVertical: props.vertical,
    });

    return (
        <div className={className}>
            <div className={classes.label}>{props.label}</div>

            {props.children ? (
                <React.Fragment>{props.children}</React.Fragment>
            ) : (
                <div className={classes.content}>{props.content || '-'}</div>
            )}
        </div>
    );
};

InfoItem.propTypes = {
    content: PropTypes.string,
    label: PropTypes.string,
    vertical: PropTypes.bool,
};

export default InfoItem;
