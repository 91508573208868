import React, { Component } from 'react'
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'

export default class SearchBar extends Component {
    render() {
        return (
            <div className="search-bar-container" >
                <form className="input-search" style={{width: this.props.widthInput}}>
                    <input type="text" placeholder={this.props.placeholder} onChange={this.props.onChange} value={this.props.value}/>
                    <div className="icone" onClick={this.props.onClickIcon}>
                        <FontAwesomeIcon icon="search" />
                    </div>
                    <button
                        className='search-submit'
                        type='submit'
                        onClick={(e) => {
                            e.preventDefault();
                            if (this.props.onClickIcon) this.props.onClickIcon()
                            else this.props.onClickButton()
                        }}
                        />
                </form>
                <Button color="primary" onClick={this.props.onClickButton}>{this.props.textoBotao}</Button>
            </div>
        )
    }
}
