import React, { Component } from 'react'
import { baseURL } from '../../services/api'

export default class AttachmentListing extends Component {
    render() {
        if (!this.props.data || this.props.data.length < 1) return null
        return (
            <div className="listing-item attachments">
                <div className="item-tag">Anexos:</div>
                <div className="item-value">
                    <div className="attachment-listing">
                        {this.props.data.map((item, index) => (
                            <a href={`${baseURL}${item.path}`} download key={index}>
                                <div className="attachment-item">{item.original_name}</div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
