import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router';

import { auth } from './auth'

const reducers = history =>
    combineReducers({
        auth,
        router: connectRouter(history),
    });

export default reducers;