import React, { Component } from 'react'
import './MessagesListing.styles.scss'
import MessageItem from '../MessageItem/MessageItem'

export default class MessagesListing extends Component {
    render() {
        return (
            <div className="new-message-listing">
                {this.props.data &&
                    this.props.data.map((item, index) => (
                        <MessageItem
                            key={index}
                            id={item.id}
                            isAnonymous={!item.author}
                            mine={item.author && (item.author.role.id == 1 || item.author.role.id == 2)}
                            messageCreator={item.author && `${item.author.first_name} ${item.author.last_name || ''}`}
                            messageContent={item.content}
                            messageDate={this.formatDate(item.created_at)}
                            messageUpdated={
                                item.created_at !== item.updated_at &&
                                `Atualizado em ${this.formatDate(item.updated_at, true)}`
                            }
                            onEditAdminReply={this.props.onEditAdminReply}
                            onDeleteAdminReply={this.props.onDeleteAdminReply}
                            attachments={item.attachments}
                        />
                    ))}
            </div>
        )
    }

    formatDate = (date, hideHours) => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hour = date.substr(10, 6)

        let text = `${day}/${month}/${year}`

        if (!hideHours) text += ` às ${hour}`

        return text
    }
}
