import React from 'react'
import classNames from 'classnames'
import iconError from '../../assets/images/icon-error.png'
import './style.scss'

/**
 *
 * @param {string} props.type
 * @param {string} props.size
 * @param {*} props.mensagemErro
 */
const MessageBox = props => {
    const { type = 'danger', size = '' } = props

    const className = classNames('MessageBox', [type], [size])

    return (
        !props.mensagemErro
        ?
        <div/>
        :
        
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={className} style={{ display: 'inline-block' }}>
                <div style={{display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                    <div className="MessageBox-icon">
                        <img src={iconError} alt="" />
                    </div>
                    <span className="MessageBox-text">{props.mensagemErro}</span>
                </div>
            </div>
        </div>
    )
}

export default MessageBox
