import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import CabecalhoListagem from '../../components/CabecalhoListagem'
import ListagemItem from '../../components/ListagemItem'
import TextInput from '../../components/Inputs/TextInput'
import SelectInput from '../../components/Inputs/SelectInput'
import Button from '../../components/Button/Button'
import MessageBox from '../../components/MessageBox'
import Modal from '../../components/Modal'
import ModalSuccess from '../../components/ModalSuccess'
import ModalConfirmation from '../../components/ModalConfirmation'
import LoaderFull from '../../components/LoaderFull'
import api, { baseURL } from '../../services/api'
import axios from 'axios'
import { Helmet } from 'react-helmet'

const statusArray = [{ id: 1, name: 'Ativo' }, { id: 0, name: 'Inativo' }]

export default class GestaoContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showEdit: false,
            loading: false,
            mensagemErro: '',
            errors: {},
            items: [],

            name: '',
            sort: 0,
            status: 1,
            editId: 0,
            migrateTo: 0,

            modalTitle: '',
            modalMessage: '',
            formError: '',

            notEditable: []
        }

        this.modalSuccessRef = React.createRef()
        this.modalConfirmationRef = React.createRef()
    }

    render() {
        const { items, mensagemErro, errors, name, status, sort, editId, formError, notEditable, migrateTo } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>{`${this.props.headerTitle} - JCC Ouvidoria`}</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <Modal title={this.state.modalTitle} ref={this.modalSuccessRef}>
                    <ModalSuccess text={this.state.modalMessage} />
                </Modal>
                <Modal title={`Tem certeza que deseja excluir este item?`} ref={this.modalConfirmationRef}>
                    <ModalConfirmation
                        onYes={this.handleDelete}
                        onNo={() => this.closeModal(this.modalConfirmationRef)}
                    >
                        {editId > 0 && (
                            <div className="input-line select-modal-container">
                                <div className="input-item select-modal">
                                    <SelectInput
                                        labelInput="Migrar ocorrências para:"
                                        value={migrateTo}
                                        defaultValue={1}
                                        options={items.filter(item => item.id !== editId)}
                                        onChange={e => this.setState({ migrateTo: e.target.value })}
                                    />
                                </div>
                            </div>
                        )}
                    </ModalConfirmation>
                </Modal>
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="admin-gestao-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="titulo-gestao">
                                        {this.props.headerTitle}
                                        <div className="button-item">
                                            <Button color="primary" onClick={this.registerNew}>
                                                Adicionar
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showEdit ? 'block' : 'none' }}>
                                        <div className="container-gestao-edicao">
                                            <div className="input-line">
                                                <div className="input-item">
                                                    <TextInput
                                                        labelInput="Nome"
                                                        value={name}
                                                        onChange={e => this.setState({ name: e.target.value })}
                                                        msgErro={errors.name}
                                                    />
                                                </div>
                                                <div className="input-item">
                                                    <TextInput
                                                        labelInput="Ordem"
                                                        value={sort}
                                                        onChange={e => this.setState({ sort: e.target.value })}
                                                        msgErro={errors.sort}
                                                    />
                                                </div>
                                                <div className="input-item">
                                                    <SelectInput
                                                        labelInput="Status"
                                                        value={status}
                                                        defaultValue={1}
                                                        options={statusArray}
                                                        onChange={e => this.setState({ status: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <MessageBox mensagemErro={formError} />
                                            </div>
                                            <div className="container-botoes-submit">
                                                <div className="button-item">
                                                    <Button color="primary" onClick={this.handleSubmit}>
                                                        Salvar
                                                    </Button>
                                                </div>
                                                {editId > 0 && (
                                                    <div className="button-item">
                                                        <Button
                                                            color="primary"
                                                            onClick={() => this.openModal(this.modalConfirmationRef)}
                                                        >
                                                            Excluir
                                                        </Button>
                                                    </div>
                                                )}
                                                <div className="button-item">
                                                    <Button color="primary" outline onClick={this.closeEdit}>
                                                        Cancelar
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="listagem-container">
                                        <CabecalhoListagem items={['Nome', 'Ordem', 'Status']} gestao={true} />
                                        {mensagemErro ? (
                                            <div className="msg-sem-items">{mensagemErro}</div>
                                        ) : (
                                            items.map((item, index) => (
                                                <ListagemItem
                                                    key={index}
                                                    gestao={true}
                                                    onClick={() => this.openEdit(item)}
                                                    name={item.name}
                                                    sort={item.sort}
                                                    status={item.status == 1 ? 'Ativo' : 'Inativo'}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {/* <Pagination/> */}
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = async () => {
        const { endpoint, plural, configFilter } = this.props

        try {
            this.setState({ loading: true, mensagemErro: '' })

            const requests = await axios.all([
                api.get(`${baseURL}${plural || endpoint + 's'}?per_page=-1&order_by=name`),
                api.get(`${baseURL}configs?search=${configFilter}`)
            ])

            const items = requests[0].data.data.items
            const content = requests[1].data.data.items.length ? requests[1].data.data.items[0].content : []
            const notEditable = content.map(item => item.not_editable)

            this.setState({
                loading: false,
                items,
                notEditable,
                mensagemErro: items.length ? '' : 'Não existem itens cadastrados.',
                migrateTo: items[0].id
            })
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    registerNew = () => this.setState({ showEdit: true, name: '', status: 1, sort: 0, editId: 0, formError: '' })
    openEdit = item => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0

        this.setState({ showEdit: true, name: item.name, sort: item.sort, status: item.status, editId: item.id, formError: '' })
    }
    closeEdit = () => this.setState({ showEdit: false, name: '', status: 1, editId: 0, sort: 0 })
    openModal = modalRef => modalRef.current.openModal()
    closeModal = modalRef => modalRef.current.closeModal()

    validateFields = () => {
        let errors = {}

        if (this.state.name.length < 3) errors.name = 'Nome deve ter no mínimo 3 caracteres.'

        if (this.state.sort.length < 1) errors.sort = 'Ordem é obrigatório.'

        this.setState({ errors })

        return JSON.stringify(errors) === '{}'
    }

    handleSubmit = async () => {
        if (!this.validateFields()) return

        const { editId, name, status, sort } = this.state
        const { title, endpoint, plural } = this.props

        try {
            this.setState({ loading: true, mensagemErro: '' })

            let modalTitle = ''
            let modalMessage = ''

            if (editId) {
                await api.put(`${baseURL}${endpoint}/${editId}`, { name, status, sort })
                modalTitle = name
                modalMessage = `${title} alterado com sucesso.`
            } else {
                await api.post(`${baseURL}${endpoint}`, { name, status, sort })
                modalTitle = `Novo ${title}`
                modalMessage = `${title} adicionado com sucesso.`
            }

            const response = await api.get(`${baseURL}${plural || endpoint + 's'}?per_page=-1&order_by=name`)

            const items = response.data.data.items

            this.closeEdit()
            this.setState({ items, modalTitle, modalMessage })
            this.openModal(this.modalSuccessRef)
        } catch (error) {
            this.setState({ mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde' })
        } finally {
            this.setState({ loading: false })
        }
    }

    handleDelete = async () => {
        const { editId, migrateTo } = this.state
        const { endpoint, plural } = this.props

        try {
            this.closeModal(this.modalConfirmationRef)

            this.setState({ loading: true, mensagemErro: '', formError: '' })

            await api.put(`${baseURL}${endpoint}/migrate`, { migrate_from: editId, migrate_to: migrateTo })

            await api.delete(`${baseURL}${endpoint}/${editId}`)

            const response = await api.get(`${baseURL}${plural || endpoint + 's'}?per_page=-1&order_by=name`)

            const items = response.data.data.items

            this.closeEdit()
            this.setState({
                items,
                modalTitle: `Excluir ${this.props.title}`,
                modalMessage: `${this.props.title} excluído com sucesso.`
            })
            this.openModal(this.modalSuccessRef)
        } catch (error) {
            let formError = ''
            let mensagemErro = ''

            if (error.response.status === 424)
                formError = 'Você deve migrar todas as ocorrências dessa entidade antes de removê-la.'
            else if (error.response.status === 500)
                mensagemErro = 'Ocorreu um erro inesperado. Tente novamente mais tarde'

            this.setState({ mensagemErro, formError })
        } finally {
            this.setState({ loading: false })
        }
    }
}
