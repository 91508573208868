import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.scss'
export default class PasswordInput extends Component {
  constructor(props){
    super(props);
    this.state = {
      passwordMask: true,
    };
  }

  togglePasswordVisibility = () => {
      this.setState({ passwordMask: !this.state.passwordMask });
  };


  render() {
    return (
    <div className="item-input">
        <label style={{color: this.props.colorLabel}}>{this.props.labelInput}</label>
        <input type={this.state.passwordMask ? 'password' : 'text'} onChange={this.props.onChange} value={this.props.value}/>
        {
          this.props.msgErro &&
          <div className="msg-erro"><FontAwesomeIcon icon="info-circle" /> {this.props.msgErro}</div>
        }
        <div className="password-trigger" onClick={() => this.togglePasswordVisibility()}>
          <FontAwesomeIcon icon="eye" />
        </div>
    </div>
    )
  }
}
