import React, { Component } from 'react'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import ResponsiveHeader from '../../../components/ResponsiveHeader'
import ContentWrapper from '../../../components/PageWrapper/ContentWrapper'
import FooterBar from '../../../components/FooterBar'
import BorderCard from '../../../components/Cards/BorderCard'
import Button from '../../../components/Button/Button'
import arvore from '../../../assets/images/arvore-card.png'
import { Helmet } from 'react-helmet'

export default class PasswordRecoveryThree extends Component {
    state = {
        bgIndex: 1
    }

    render() {
        return (
            <PageWrapper>
                <Helmet>
                    <title>Senha Redefinida - JCC Ouvidoria</title>
                </Helmet>
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="bg-container-login">
                        <div className={`bg-item ${this.state.bgIndex === 1 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-1.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 2 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-4.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 3 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-5.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 4 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-6.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 5 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-7.jpg')} />
                        </div>
                        <div className={`bg-item ${this.state.bgIndex === 6 ? 'active' : ''}`}>
                            <img src={require('../../../assets/images/bg-intro-8.jpg')} />
                        </div>
                    </div>
                    <div className={`container-login-page`}>
                        <div className="container-card-recovery">
                            <BorderCard corBorda="transparent">
                                <div className="conteudo-card-login">
                                    <div className="img-arvore">
                                        <img src={arvore} alt="" />
                                    </div>
                                    <div className="big-titulo">Senha redefinida com sucesso.</div>
                                    <div className="subtitulo">
                                        Sua senha foi redefinida com sucesso. Prossiga para o login do Canal da
                                        Ouvidoria
                                    </div>
                                    <div className="buttons-container">
                                        <div className="button-item">
                                            <Button onClick={() => this.props.history.push('/')} color="primary">
                                                FAZER LOGIN
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </BorderCard>
                        </div>
                    </div>
                </ContentWrapper>
                <div style={{ position: 'relative', zIndex: 100 }}>
                    <FooterBar />
                </div>
            </PageWrapper>
        )
    }

    componentDidMount = () => {
        this.bgInterval = setInterval(
            () =>
                this.setState({
                    bgIndex: this.state.bgIndex < 6 ? this.state.bgIndex + 1 : 1
                }),
            5000
        )
    }

    componentWillUnmount = () => {
        clearInterval(this.bgInterval)
    }
}
