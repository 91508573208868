import React, { Component } from 'react'
import classNames from 'classnames'
import './style.scss'

import { AuthActions } from '../../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

class MobileBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobileMenuEnabled: false
        }
    }

    toggleMobileMenu() {
        this.setState({
            isMobileMenuEnabled: !this.state.isMobileMenuEnabled
        })
    }
    render() {
        const activeClass = classNames('burger-icon-container', {
            'is-active': this.state.isMobileMenuEnabled
        })
        const activeContent = classNames('menu-content', {
            'is-active': this.state.isMobileMenuEnabled
        })
        return (
            <div className="mobile-bar">
                <div className="top-bar">
                    <Link to="/" className="logo">
                        <img src={this.props.logo} alt="" />
                    </Link>

                    <div className={activeClass} onClick={() => this.toggleMobileMenu()}>
                        <div className="burger-icon color-light" />
                    </div>
                </div>
                <div className={activeContent}>
                    {this.props.user ? (
                        <ul className="listagem-menu">
                            <li className="menu-item">
                                <Link to="/">Início</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin-ocorrencias">Ocorrências</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/admin-clientes">Clientes</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/administradores">Gestão de Administradores</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/assuntos">Gestão de Assuntos</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/localidades">Gestão de Localidades</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/setores">Gestão de Setores</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/status">Gestão de Status</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/tipos">Gestão de Tipos</Link>
                            </li>
                            <li className="menu-item">
                                <Link to="/perfil">Meus Dados</Link>
                            </li>
                            <li className="menu-item" onClick={this.props.logout}>
                                Sair
                            </li>
                        </ul>
                    ) : (
                        <ul className="listagem-menu">
                            <li className="menu-item">
                                <a href="https://jccbr.com/ouvidoria/codigo-de-etica.pdf" target="_blank">
                                    Código de ética
                                </a>
                            </li>
                            <li className="menu-item">
                                <Link to="/login">Login</Link>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = dispatch => bindActionCreators({ ...AuthActions }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileBar)
