import React, { Component } from "react";
import classes from "./ResponsiveHeader.module.scss";
import logo from "../../assets/images/logo-jcc.png";
import DesktopBar from "./DesktopBar";
import MobileBar from "./MobileBar";

export default class ResponsiveHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobileMenuEnabled: false,
            headerSize: 0,
        };

        this.headerEl = React.createRef();
    }

    toggleMobileMenu() {
        this.setState({
            isMobileMenuEnabled: !this.state.isMobileMenuEnabled,
        });
    }

    componentDidMount() {
        this.setState({ headerSize: this.headerEl.current.offsetHeight });
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.headerEl} className={classes.ResponsiveHeader}>
                    <DesktopBar logo={logo} />
                    <MobileBar logo={logo} />
                </div>

                <div
                    className="header-clearance"
                    style={{ paddingTop: this.state.headerSize + "px" }}
                />
            </React.Fragment>
        );
    }
}
