import React, { Component } from 'react'
import './ReplyField.styles.scss'
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup'
import Button from '../Button/Button'
import MessageBox from '../../components/MessageBox'
import logo from '../../assets/images/logo-jcc-no-text.png'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import TextInputMask from '../Inputs/TextInputMask'
import SelectInput from '../Inputs/SelectInput'

const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
]

const WEEKDAYS_LONG = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado']

const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

export default class ReplyField extends Component {
    state = { showDatePicker: false }

    modifiers = {
        highlighted: this.state.deadline
    }

    modifiersStyles = {
        highlighted: {
            color: 'white',
            backgroundColor: '#ffb111'
        }
    }

    inputWrapperRef = React.createRef()

    render() {
        if (this.props.isFinished) {
            return (
                <div className="reply-field-container">
                    <div className="input-group-status">
                        <span className="label">Status:</span>
                        <SelectInput
                            defaultValue={this.props.statusData.value}
                            value={this.props.statusData.value}
                            options={this.props.statusData.options}
                            onChange={this.props.statusData.onChange}
                        />
                    </div>
                    <div style={{ marginBottom: 25 }}>
                        <MessageBox mensagemErro={this.props.msgError} />
                    </div>
                    <Button onClick={this.props.onButtonClick}>Enviar</Button>
                </div>
            )
        }

        return (
            <div className="reply-field-container">
                <div className="title">
                    <img src={logo} alt="" />
                    Resposta da Ouvidoria
                </div>
                <div className="small-title" style={{ marginBottom: 10 }}>
                    Comentário
                </div>
                <textarea rows={5} width="100%" value={this.props.textValue} onChange={this.props.onTextChange} />
                <div className="input-group-status">
                    <span className="label">Status:</span>
                    <SelectInput
                        defaultValue={this.props.statusData.value}
                        value={this.props.statusData.value}
                        options={this.props.statusData.options}
                        onChange={this.props.statusData.onChange}
                    />
                </div>
                {this.props.textValue.length > 0 && (
                    <CheckboxGroup
                        checked={this.props.allowReply}
                        onClick={this.props.onCheckboxClick}
                        label="Permitir réplica do usuário"
                        id="allow-reply"
                    />
                )}
                {this.props.textValue.length > 0 && this.props.allowReply && (
                    <div className="input-group-status">
                        <div className="datepicker-input" ref={this.inputWrapperRef}>
                            <TextInputMask
                                labelInput="Expira em "
                                value={this.props.deadline}
                                onChange={this.props.onDeadlineChange}
                                mask={'99/99/9999'}
                                isIconRight={true}
                                iconName="calendar"
                                onIconClick={() =>
                                    this.setState({
                                        showDatePicker: !this.state.showDatePicker
                                    })
                                }
                            />
                            {this.state.showDatePicker && (
                                <div className="datepicker-container">
                                    <DayPicker
                                        locale={'br'}
                                        months={MONTHS}
                                        weekdaysLong={WEEKDAYS_LONG}
                                        weekdaysShort={WEEKDAYS_SHORT}
                                        firstDayOfWeek={0}
                                        onDayClick={this.props.handleDayChange}
                                        selectedDays={this.props.deadline}
                                        modifiers={this.modifiers}
                                        modifiersStyles={this.modifiersStyles}
                                        showOutsideDays
                                        fixedWeeks
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div style={{ marginBottom: 25 }}>
                    <MessageBox mensagemErro={this.props.msgError} />
                </div>
                <Button onClick={this.props.onButtonClick}>Enviar</Button>
            </div>
        )
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick)
    }

    handleOutsideClick = event => {
        if (this.inputWrapperRef.current && !this.inputWrapperRef.current.contains(event.target)) {
            this.setState({
                showDatePicker: false
            })
        }
    }
}
