import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import Button from '../../components/Button/Button'
import InfoItemListagem from '../../components/InfoItemListagem'
import PasswordInput from '../../components/Inputs/PasswordInput'
import TextInput from '../../components/Inputs/TextInput'
import TextInputMask from '../../components/Inputs/TextInputMask'
import MessageBox from '../../components/MessageBox'
import Modal from '../../components/Modal'
import ModalSuccess from '../../components/ModalSuccess'
import LoaderFull from '../../components/LoaderFull'

import { AuthActions } from '../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import api, { baseURL } from '../../services/api'
import { Helmet } from 'react-helmet'

class UserProfile extends Component {
    constructor(props) {
        super(props)

        this.modalConfirmation = React.createRef()
        this.modalSuccessRef = React.createRef()

        this.state = {
            showEdit: false,
            first_name: props.user.first_name || '',
            last_name: props.user.last_name || '',
            email: props.user.email || '',
            cellphone: props.user.cellphone || '',
            password: '',
            password_confirmation: '',
            errors: {},
            mensagemErro: ''
        }
    }

    toggleEdit = () => {
        this.setState({
            showEdit: !this.state.showEdit
        })
    }

    render() {
        const { user } = this.props
        const {
            showEdit,
            errors,
            first_name,
            last_name,
            email,
            password,
            password_confirmation,
            cellphone
        } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>Minha Conta - JCC Ouvidoria</title>
                </Helmet>

                <Modal title="Alteração de perfil" size="small" ref={this.modalSuccessRef}>
                    <ModalSuccess text="Seu perfil foi atualizado com sucesso" />
                </Modal>

                <Modal ref={this.modalConfirmation} title={'Aviso'} size="small">
                    <div className="margin-top">
                        <div>Deseja realmente alterar as informações de perfil?</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '2rem auto 0'
                            }}
                        >
                            <button
                                className="button primary outline margin-right"
                                onClick={() => this.closeModal(this.modalConfirmation)}
                            >
                                Não
                            </button>
                            <button
                                onClick={() => {
                                    this.closeModal(this.modalConfirmation)
                                    this.handleSubmit()
                                }}
                                className="button primary"
                            >
                                Sim
                            </button>
                        </div>
                    </div>
                </Modal>

                <LoaderFull visible={this.state.loading} />

                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="page-profile-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <div className="titulo">Minha Conta</div>
                                    {showEdit ? (
                                        <div
                                            className="container-alterar-senha"
                                            style={{ display: showEdit ? 'block' : 'none' }}
                                        >
                                            <div className="flex-container">
                                                <div className="altera-item">
                                                    <TextInput
                                                        msgErro={errors.first_name}
                                                        labelInput="Nome:"
                                                        value={first_name}
                                                        onChange={e => this.setState({ first_name: e.target.value })}
                                                    />
                                                </div>
                                                <div className="altera-item">
                                                    <TextInput
                                                        msgErro={errors.last_name}
                                                        labelInput="Sobrenome:"
                                                        value={last_name}
                                                        onChange={e => this.setState({ last_name: e.target.value })}
                                                    />
                                                </div>
                                                <div className="altera-item">
                                                    <TextInputMask
                                                        msgErro={errors.cellphone}
                                                        labelInput="Telefone:"
                                                        value={cellphone}
                                                        onChange={e => this.setState({ cellphone: e.target.value })}
                                                        isIconRight={true}
                                                        mask={'(99) 999999999'}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-container">
                                                <div className="altera-item">
                                                    <TextInput
                                                        msgErro={errors.email}
                                                        labelInput="Email:"
                                                        value={email}
                                                        onChange={e => this.setState({ email: e.target.value })}
                                                    />
                                                </div>
                                                <div className="altera-item">
                                                    <PasswordInput
                                                        msgErro={errors.password}
                                                        labelInput="Nova Senha:"
                                                        value={password}
                                                        onChange={e => this.setState({ password: e.target.value })}
                                                    />
                                                </div>
                                                <div className="altera-item">
                                                    <PasswordInput
                                                        msgErro={errors.password_confirmation}
                                                        labelInput="Confirmar Nova Senha:"
                                                        value={password_confirmation}
                                                        onChange={e =>
                                                            this.setState({ password_confirmation: e.target.value })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="listagem-container">
                                            <InfoItemListagem
                                                tituloListagem="Nome Completo"
                                                conteudoListagem={user.first_name + ' ' + user.last_name}
                                                fullWidth={true}
                                            />
                                            <div className="linha-listagem">
                                                <InfoItemListagem
                                                    tituloListagem="Telefone"
                                                    conteudoListagem={this.formatCellphone(user.cellphone)}
                                                />
                                                <InfoItemListagem
                                                    tituloListagem="E-mail"
                                                    conteudoListagem={user.email}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* <div className="listagem-container">
                                            <InfoItemListagem tituloListagem="Nome Completo" conteudoListagem={user.first_name + ' ' + user.last_name}/>
                                            <div className="linha-listagem">
                                                <InfoItemListagem tituloListagem="CPF/CNPJ" conteudoListagem={user.document_number}/>
                                                <InfoItemListagem tituloListagem="E-mail" conteudoListagem={user.email}/>
                                            </div>
                                            <InfoItemListagem tituloListagem="Telefone" conteudoListagem={user.cellphone}/>

                                        </div>
                                        <div className="container-alterar-senha" style={{ display: this.state.showEdit ? 'block' : 'none' }}>
                                            <div className="flex-container">
                                                <div className="altera-item">
                                                    <PasswordInput msgErro="Senha incorreta"labelInput="Nova Senha:"/>
                                                </div>
                                                <div className="altera-item">
                                                    <PasswordInput msgErro="Senha incorreta"labelInput="Confirmar Nova Senha:"/>
                                                </div>

                                            </div>
                                        </div> */}

                                    <MessageBox inlineBlock mensagemErro={this.state.mensagemErro} />

                                    <div className="margin-top" />

                                    <div className="container-botao" style={{ display: !showEdit ? 'block' : 'none' }}>
                                        <Button color="primary" onClick={() => this.toggleEdit()}>
                                            alterar informações
                                        </Button>
                                    </div>
                                    <div style={{ display: showEdit ? 'block' : 'none' }}>
                                        <div className="flex-container">
                                            <div className="container-botao">
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.openModal(this.modalConfirmation)}
                                                >
                                                    salvar alteração
                                                </Button>
                                            </div>
                                            <div className="container-botao">
                                                <Button color="primary outline" onClick={() => this.toggleEdit()}>
                                                    cancelar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    validateFields = () => {
        const { first_name, last_name, password, password_confirmation, cellphone } = this.state
        let errors = {}

        if (first_name.length < 3) errors.first_name = 'Nome deve ter ao menos 3 caractéres'
        if (last_name.length < 3) errors.last_name = 'Sobrenome deve ter ao menos 3 caractéres'
        if (!this.isEmailValid()) errors.email = 'Email inválido'
        if (password.length > 0 && password.length < 8) errors.password = 'A senha deve ter ao menos 8 caractéres'
        if (password_confirmation !== password) errors.password_confirmation = 'As senhas não correspondem'
        if (cellphone.length < 13) errors.cellphone = 'Telefone inválido'

        this.setState({ errors, mensagemErro: '' })

        if (JSON.stringify(errors) !== '{}') return false

        return true
    }

    handleSubmit = async () => {
        if (!this.validateFields()) return

        const { user } = this.props
        const { first_name, last_name, email, password, password_confirmation, cellphone } = this.state
        let send = {}

        if (first_name !== user.first_name) send.first_name = first_name
        if (last_name !== user.last_name) send.last_name = last_name
        if (email !== user.email) send.email = email
        if (cellphone.replace(/[^\d]/g, '') !== user.cellphone) send.cellphone = cellphone.replace(/[^\d]/g, '')

        if (password) {
            send.password = password
            send.password_confirmation = password_confirmation
        }

        try {
            this.setState({ loading: true })

            const response = await api.put(`${baseURL}administrator/${this.props.user.id}`, send)

            this.props.updateUser(response.data.data)

            this.setState({ loading: false, mensagemErro: '' }, () => {
                this.toggleEdit()
                this.openModal(this.modalSuccessRef)
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    isEmailValid = () => {
        const regex = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+$/
        return regex.test(this.state.email)
    }

    openModal = modalRef => {
        modalRef.current.openModal()
    }

    closeModal = modalRef => {
        modalRef.current.closeModal()
    }

    formatCellphone = cellphone => {
        if (!cellphone) return '-'

        return `(${cellphone.substr(0, 2)}) ${cellphone.substr(2)}`
    }
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

const mapDispatchToProps = dispatch => bindActionCreators({ ...AuthActions }, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile)
