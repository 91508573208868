import React, { Component } from 'react'
import ResponsiveHeader from '../../components/ResponsiveHeader'
import FooterBar from '../../components/FooterBar'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import ContentWrapper from '../../components/PageWrapper/ContentWrapper'
import './style.scss'
import BorderCard from '../../components/Cards/BorderCard'
import SearchBar from '../../components/SearchBar'
import CabecalhoListagem from '../../components/CabecalhoListagem'
import ListagemItem from '../../components/ListagemItem'
import Pagination from '../../components/Pagination'
import TitleClient from '../../components/Title/TitleClient'
import InfoItem from '../../components/InfoItem/InfoItem'

import LoaderFull from '../../components/LoaderFull'
import api, { baseURL } from '../../services/api'
import { Helmet } from 'react-helmet'

export default class ClientInternaPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mensagemErro: '',
            client: null,
            occurrences: [],
            occurrencesTotal: 0,
            pageCount: 0
        }
    }

    render() {
        const { client, mensagemErro, occurrences, occurrencesTotal } = this.state

        return (
            <PageWrapper>
                <Helmet>
                    <title>{`${(client && client.first_name + ' - ') || ''}JCC Ouvidoria`}</title>
                </Helmet>
                <LoaderFull visible={this.state.loading} />
                <ResponsiveHeader />
                <ContentWrapper>
                    <div className="admin-clientes-protocolo-container small-bg">
                        <div className="container">
                            <div className="container-card">
                                <BorderCard corBorda="transparent">
                                    <header className="header-container">
                                        <TitleClient
                                            content={client && client.first_name}
                                            margin="small"
                                            occurrences={occurrencesTotal}
                                            goBackText="Voltar para Clientes"
                                            goBackClick={() => this.props.history.goBack()}
                                        />
                                        <div className="form-row">
                                            <div className="form-col">
                                                <InfoItem
                                                    label="E-mail"
                                                    content={client && client.email}
                                                    contentSize="small"
                                                />
                                            </div>
                                            <div className="form-col">
                                                <InfoItem
                                                    label="Último login"
                                                    content={client && this.formatDate(client.last_login)}
                                                    contentSize="small"
                                                />
                                            </div>
                                        </div>
                                    </header>
                                    <div className="listagem-container">
                                        <CabecalhoListagem
                                            items={[
                                                'Protocolo',
                                                'Local',
                                                'Data de Criação',
                                                'Última atualização',
                                                'Situação',
                                                'Status'
                                            ]}
                                        />
                                        {mensagemErro ? (
                                            <div className="msg-sem-ocorrencias">{mensagemErro}</div>
                                        ) : (
                                            occurrences.map((item, index) => (
                                                <ListagemItem
                                                    onClick={() =>
                                                        this.props.history.push(`/admin-ocorrencia/${item.id}`)
                                                    }
                                                    valorProtocolo={item.protocol_number}
                                                    valorLocal={item.mall.name}
                                                    valorCriacao={this.formatDate(item.created_at)}
                                                    valorAtt={this.formatDate(item.updated_at)}
                                                    valorStatus={item.status && item.status.name}
                                                    key={index}
                                                    isClientInterna={true}
                                                    valorSituacao={item.substatus}
                                                    hasReports={item.reports.length > 0}
                                                    hasNotes={item.notes.length > 0}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {this.state.pageCount > 1 && (
                                        <Pagination
                                            pageCount={this.state.pageCount}
                                            onPageChange={data => this.loadOccurrences(data.selected + 1)}
                                        />
                                    )}
                                </BorderCard>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
                <FooterBar />
            </PageWrapper>
        )
    }

    componentDidMount = async () => {
        try {
            this.setState({ loading: true, mensagemErro: '' })

            const responseClient = await api.get(`${baseURL}customer/${this.props.match.params.id}`)
            const client = responseClient.data.data

            const responseOccurrences = await api.get(`${baseURL}occurrences?customer=${client.email}&order=desc`)
            const occurrences = responseOccurrences.data.data.items

            let pageCount = responseOccurrences.data.data.totals / 20
            if (Number.isInteger(pageCount)) pageCount = Number.parseInt(pageCount) + 1

            this.setState({
                loading: false,
                client,
                pageCount,
                occurrences,
                occurrencesTotal: responseOccurrences.data.data.totals,
                mensagemErro: occurrences.length ? '' : 'Não existem ocorrências cadastradas.'
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    loadOccurrences = async page => {
        try {
            this.setState({ loading: true, mensagemErro: '' })

            const responseOccurrences = await api.get(
                `${baseURL}occurrences?customer=${this.state.client.email}&current_page=${page}`
            )
            const occurrences = responseOccurrences.data.data.items

            this.setState({
                loading: false,
                occurrences,
                mensagemErro: occurrences.length ? '' : 'Não existem ocorrências cadastradas.'
            })
        } catch (error) {
            this.setState({ loading: false, mensagemErro: 'Ocorreu um erro inesperado. Tente novamente mais tarde.' })
        }
    }

    formatDate = date => {
        if (!date) return '-'

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)
        const hours = date.substr(11, 5)

        return `${day}/${month}/${year}`
    }
}
