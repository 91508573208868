import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames';
import './style.scss'


export default class IconInput extends Component {
    constructor(props){
        super(props);
        this.state = {
            isIconRight: this.props.isIconRight,
        }
    }   
    render() {
    const sideControl= classNames("icon-container", {"right": this.state.isIconRight, "left": !this.state.isIconRight})
    const paddingControl= classNames("input", {"padding": !this.state.isIconRight})
    return (
    <div className="item-input icon-inside">
        <label style={{color: this.props.colorLabel}}>{this.props.labelInput}</label>
        <input type="text" className={paddingControl}/>
        <div className="msg-erro">{this.props.msgErro}</div>
        <div className={sideControl}>
          <FontAwesomeIcon icon={this.props.iconName} />
        </div>
    </div>
    )
  }
}
