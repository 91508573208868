import { LOGIN, LOGIN_ERROR, LOGIN_LOADING, LOGOUT, UPDATE_USER } from './actionTypes'
import api from '../../services/api'

const loginUser = (email, password) => {

    return async (dispatch) => {
        
        dispatch({ type: LOGIN_LOADING, payload: true })

        try {
            const response = await api.post(
                `administrator/login`,
                {
                    'email': email,
                    'password': password,
                }
            )
            
            const userData = response.data.data
            localStorage.setItem('@IguatemiOuvidoriaAdmin:token', userData.token)
            localStorage.setItem('@IguatemiOuvidoriaAdmin:admin', JSON.stringify(userData))

            dispatch({ type: LOGIN, payload: userData })
            dispatch({ type: LOGIN_LOADING, payload: false })
        }
        catch(error) {

            const status = error.response.status
                
            let msg = ''
            if(status === 401) msg = 'Email ou senha incorretos ou usuário não autorizado'
            else if(status === 403) msg = 'Acesso negado'
            else if(status === 500) msg = 'Ocorreu um erro inesperado. Tente novamente mais tarde.'

            localStorage.clear()
            dispatch({ type: LOGIN_ERROR, payload: {status, msg} })
            dispatch({ type: LOGIN_LOADING, payload: false })
        }
    }
}

const loginMe = (id) => {

    return async (dispatch) => {
        
        dispatch({ type: LOGIN_LOADING, payload: true })

        try {
            await api.get('refresh')
            const response = await api.get(`administrator/${id}`)

            const userData = response.data.data
            localStorage.setItem('@IguatemiOuvidoriaAdmin:token', userData.token)
            localStorage.setItem('@IguatemiOuvidoriaAdmin:admin', JSON.stringify(userData))

            dispatch({ type: LOGIN, payload: userData })
        }
        catch(error) {
            const status = error.response.status
            localStorage.clear()
            dispatch({ type: LOGIN_ERROR, payload: {status, msg: ''} })
        }
        finally {
            dispatch({ type: LOGIN_LOADING, payload: false })
        }
    }
}

const logout = () => {

    return async (dispatch) => {
        
        dispatch({ type: LOGIN_LOADING, payload: true })
        
        try {
            await api.delete(`logout`)
            dispatch({ type: LOGOUT, payload: true })
            localStorage.clear()
            window.location.reload()
        }
        catch(error) {
            dispatch({ type: LOGIN_LOADING, payload: true })
            dispatch({ type: LOGOUT, payload: true })
            console.log('error', error)
            localStorage.clear()
            window.location.reload()
        }
    }
}

const updateUser = user => {
    return dispatch => dispatch({ type: UPDATE_USER, payload: user })
}

const loginError = msg => {
    return dispatch => dispatch({ type: LOGIN_ERROR, payload: { msg } })
}

export const AuthActions = {
    loginUser, loginMe, logout, updateUser, loginError
}