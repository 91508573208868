import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default class SelectInput extends Component {
    render() {
        const { options } = this.props

        return (
            <div className="item-input">
                <label style={{ color: this.props.colorLabel }}>{this.props.labelInput}</label>
                <select
                    defaultValue={this.props.defaultValue || ''}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder}
                    className="form-control"
                >
                    {!this.props.defaultValue && <option value="">Selecione</option>}
                    {options &&
                        options.map((item, index) => {
                            return (
                                <option value={item.id} key={index}>
                                    {this.getItemName(item)}
                                </option>
                            )
                        })}
                </select>
                {this.props.msgErro ? (
                    <div className="msg-erro">
                        <FontAwesomeIcon icon="info-circle" /> {this.props.msgErro}
                    </div>
                ) : (
                    <div style={{ marginBottom: 22 }} />
                )}
            </div>
        )
    }

    getItemName = item => {
        if (item.name) return item.name

        if (item.first_name) {
            let name = item.first_name
            if (item.last_name) name += item.last_name
            return name
        }
    }
}
