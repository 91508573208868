import React, { Component } from 'react'

export default class DenouncedInfo extends Component {
    render() {
        return (
            <div>
                {this.props.denouncedName && (
                    <div className="listing-item">
                        <div className="item-tag">Denunciado:</div>
                        <div className="item-value">{this.props.denouncedName}</div>
                    </div>
                )}
                <div className="listing-item description">
                    <div className="item-tag">Descrição:</div>
                    <div className="item-value">{this.props.description}</div>
                </div>
            </div>
        )
    }
}
