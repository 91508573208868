import React, { Component } from 'react'
import iconeAdmin from '../../assets/images/icone-ouvidoria.png'
import iconeUsuario from '../../assets/images/icone-usuario.png'
import AttachmentListing from '../../components/AttachmentListing/AttachmentListing'

export default class MessageItem extends Component {
    render() {
        return (
            <div
                className="new-message-container"
                style={{
                    marginLeft: this.props.mine ? 'auto' : 0,
                    textAlign: this.props.mine ? 'right' : 'left'
                }}
            >
                <div className="new-message-info">
                    <div
                        className="message-top"
                        style={{
                            flexDirection: this.props.mine ? 'row' : 'row-reverse'
                        }}
                    >
                        <div>
                            <div className="message-owner">
                                {this.props.mine
                                    ? 'Ouvidoria'
                                    : this.props.isAnonymous
                                    ? 'Anônimo'
                                    : this.props.messageCreator}
                            </div>
                            <div className="date-message">{this.props.messageDate}</div>
                            <div className="date-update-message">{this.props.messageUpdated}</div>
                            {this.props.mine && (
                                <div className="date-update-message">{`Respondido por: ${this.props.messageCreator}`}</div>
                            )}
                        </div>
                        <div
                            className="icon-container"
                            style={{
                                marginLeft: this.props.mine ? 10 : 0,
                                marginRight: this.props.mine ? 0 : 10
                            }}
                        >
                            <img src={this.props.mine ? iconeAdmin : iconeUsuario} />
                        </div>
                    </div>
                </div>
                <div className="new-message-item" style={{ backgroundColor: this.props.mine ? '#FFF7E5' : '#fff' }}>
                    {this.props.messageContent}
                </div>
                {this.props.mine && (
                    <div>
                        <button
                            className="button see-more"
                            onClick={() =>
                                this.props.onEditAdminReply(this.props.id, this.props.messageContent, {
                                    name: this.props.messageCreator,
                                    date: this.props.messageDate
                                })
                            }
                        >
                            Editar
                        </button>
                        <button className="button delete" onClick={() => this.props.onDeleteAdminReply(this.props.id)}>
                            Excluir
                        </button>
                    </div>
                )}
                <div className="attachments-container">
                    <AttachmentListing data={this.props.attachments} />
                </div>
            </div>
        )
    }
}
