import React, { Component, Fragment } from 'react'
import Button from '../Button/Button'
import icone from '../../assets/images/file-text.png'
import { baseURL } from '../../services/api'

export default class AddReport extends Component {
    state = {
        mensagemErro: ''
    }

    render() {
        return (
            <div>
                <div className="small-title" style={{ margingBottom: 45 }}>
                    Relatório Final
                </div>
                {this.props.reports &&
                    this.props.reports.map((item, index) => (
                        <div key={index} className="relatorio-container">
                            <a href={`${baseURL}${item.path}`} download>
                                <div className="relatorio-item">
                                    <div className="icone">
                                        <img src={icone} alt="" />
                                    </div>
                                    <div className="text">
                                        <div className="relatorio-info">{this.formatDate(item.created_at)}</div>
                                        <div className="relatorio-name">{item.original_name}</div>
                                    </div>
                                </div>
                            </a>
                            <button className="button delete" onClick={() => this.props.onDeleteReport(item.id)}>
                                Excluir relatório
                            </button>
                        </div>
                    ))}
                <div className="relatorio-erro">{this.state.mensagemErro}</div>
                <Button onClick={this.checkIsLocked} style={{ marginTop: 15 }}>
                    Enviar um relatório
                </Button>
                <input
                    ref={fileInput => (this.fileInput = fileInput)}
                    style={{ display: 'none' }}
                    type="file"
                    accept="application/pdf"
                    onChange={this.props.onSendReport}
                    onClick={event => {
                        event.target.value = null
                    }}
                />
            </div>
        )
    }

    checkIsLocked = () => {
        if (!this.props.isLocked) {
            this.setState({
                mensagemErro: 'Você só pode adicionar um relatório para uma ocorrência concluída ou desqualificada.'
            })
            return
        }

        if (this.state.mensagemErro) this.setState({ mensagemErro: '' })

        this.fileInput.click()
    }

    formatDate = date => {
        if (!date) return ''

        const year = date.substr(0, 4)
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)

        return `${day}/${month}/${year}`
    }
}
