import React, { Component } from 'react'
import './style.scss'
import { Link } from 'react-router-dom'
import DesktopMenuItem from './DesktopMenuItem'
import DesktopMenuItemDropdown from './DesktopMenuItemDropdown'
import { connect } from 'react-redux'

class DesktopBar extends Component {

    state = {
        modalOpen: 0
    }

    render() {
        return (
            <div className="desktop-bar">
                <div className="container">
                    <Link to='/' className="logo">
                        <img src={this.props.logo} alt="" />
                        Canal da Ouvidoria
                    </Link>
                    {
                        this.props.user
                        ?
                        <ul className="menu-container">
                            <DesktopMenuItem textoItem="Início" linkItem="/"/>
                            <DesktopMenuItem textoItem="Ocorrências" linkItem="/admin-ocorrencias"/>
                            <DesktopMenuItem textoItem="Clientes" linkItem="/admin-clientes"/>
                            {
                                this.props.user.roles[0].role_id === 1 &&
                                <DesktopMenuItemDropdown
                                    tituloDropdown="Gestão de Informações"
                                    setModalOpen={() => this.setModalOpen(1)}
                                    showModal={this.state.modalOpen === 1}
                                    isAdmin={true}
                                    />
                            }
                            <DesktopMenuItemDropdown
                                tituloDropdown="Minha Conta"
                                setModalOpen={() => this.setModalOpen(2)}
                                showModal={this.state.modalOpen === 2}
                                />
                        </ul>
                        :
                        <ul className="menu-container">
                            <DesktopMenuItem textoItem="Código de Ética" linkUrl="https://jccbr.com/ouvidoria/codigo-de-etica.pdf"/>
                            <DesktopMenuItem textoItem="Login" linkItem="/login"/>
                        </ul>
                    }
                </div>
            </div>
        )
    }

    setModalOpen = id => {
        if(id === this.state.modalOpen) id = 0
        this.setState({ modalOpen: id })
    }
}

const mapStateToProps = ({ auth }) => ({ user: auth.user })

export default connect(mapStateToProps)(DesktopBar)