import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

const history = createBrowserHistory();

const store = createStore(
    createRootReducer(history),
    applyMiddleware(routerMiddleware(history), thunk)
)

export { store, history };
