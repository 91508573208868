import React, { Component } from 'react'
import './style.scss'
export default class CabecalhoListagem extends Component {
  render() {

    const { items, gestao } = this.props

    let itemClassName = 'cabecalho-item'
    if(gestao) itemClassName += ' gestao'

    return (
        <div className='cabecalho-container'>
            {
                items.map((text, index) => 
                    <div className={itemClassName} key={index.toString()}>
                        {text}
                    </div>
                )
            }
        </div>
    )
  }
}
