import React from 'react'
import iconCheck from '../../assets/images/icon-check.png'

/**
 * @param {string} props.text Mensagem de confirmação
 */
const ModalConfirmation = props => {
    const containerStyle = {
        padding: '3rem 0'
    }

    const textStyle = {
        fontSize: '1.6rem',
        textAlign: 'center'
    }

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        marginTop: 20
    }

    const buttonStyle = {
        maxWidth: '20rem',
        width: '100%',
        marginLeft: 20,
        marginRight: 20
    }

    return (
        <div style={containerStyle}>
            <div style={textStyle}>{props.text}</div>

            {props.children}

            <div style={buttonContainerStyle}>
                <button className="button primary" style={buttonStyle} onClick={props.onYes}>
                    {props.yesText || 'Sim'}
                </button>
                <button className="button primary outline margin-right" style={buttonStyle} onClick={props.onNo}>
                    {props.noText || 'Não'}
                </button>
            </div>
        </div>
    )
}

export default ModalConfirmation
